import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import api from '@/plugins/api';
import ProjectUpgrader from '@/plugins/projectupgrader';
import FileDownload from 'js-file-download';

const namespaced = true;

const state = {
  currentProject:
    JSON.parse(localStorage.getItem('currentProject')) || {},
  emptyProject: {
    publicId: -1,
    info: {
      version: '0.0.0',
      number: '',
      name: '',
      manager: '',
      creator: '',
      revision: '',
      loadCase: '',
      designDate: new Date().toISOString().substr(0, 10),
      revisionDate: new Date().toISOString().substr(0, 10),
      upToDate: false,
      NOxRate: 0.001,
    },
    ambient: null,
    mixtures: [],
    lambda: 0.0,
    lambdaWithSetpoint: 0.0,
    combustionChambers: [],
    flueGasSpecies: [],
    solverSettings: {},
    solverStats: {
      isConverged: false,
      log: { in: [], out: [], delta: [], rel: [] },
    },
    diagram: null,
  },
  emptySolverSettings: {
    numberOfIterations: 5,
    convergence: 1e-4,
  },
  calculationRequest: null,
};

const getters = {
  currentProject: state => state.currentProject,
  projectInfo: state => state.currentProject.info,
  upToDate: state => state.currentProject.info.upToDate,
  currentProjectFileName: state => {
    var info = state.currentProject.info;
    return `${info.number}_${info.name}_(loadcase ${info.loadCase})_(rev ${info.revision})`;
  },
  emptyProject: (state, getters, rootState, rootGetters) => () => {
    let newProject = JSON.parse(JSON.stringify(state.emptyProject));
    newProject.publicId = uuidv4();
    newProject.info.version = rootGetters['misc/version'];
    newProject.solverSettings = getters.emptySolverSettings();
    return newProject;
  },
  emptySolverSettings: state => () => {
    return JSON.parse(JSON.stringify(state.emptySolverSettings));
  },
  NOxRate: state => state.currentProject.NOxRate,
  lambda: state => state.currentProject.lambda,
  lambdaWithSetpoint: state =>
    state.currentProject.lambdaWithSetpoint,
  solverSettings: state => state.currentProject.solverSettings,
  solverStats: state => state.currentProject.solverStats,
  assembleProjectForSolver: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => project => {
    var proj = JSON.parse(JSON.stringify(project));
    proj.mixtures = proj.mixtures.map(mix => {
      return rootGetters['mixtures/assembleMixture'](mix);
    });
    proj.flueGas = rootGetters['mixtures/assembleMixture'](
      proj.flueGas,
    );
    proj.ambient = rootGetters['mixtures/assembleAmbient'](
      proj.ambient,
    );
    proj.combustionChambers = proj.combustionChambers.map(chamber => {
      return rootGetters[
        'combustionChambers/assembleCombustionChamber'
      ](chamber);
    });

    return proj;
  },
  disassembleProjectFromSolver: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => project => {
    var proj = project; //JSON.parse(JSON.stringify(project));
    proj.mixtures = proj.mixtures.map(mix => {
      return rootGetters['mixtures/disassembleMixture'](mix);
    });
    proj.flueGas = rootGetters['mixtures/disassembleMixture'](
      proj.flueGas,
    );
    proj.ambient = rootGetters['mixtures/disassembleAmbient'](
      proj.ambient,
    );
    proj.combustionChambers = proj.combustionChambers.map(chamber => {
      return rootGetters[
        'combustionChambers/disassembleCombustionChamber'
      ](chamber);
    });
    return proj;
  },
  mixturesReferenced: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters['mixtures/mixtures'] ===
      state.currentProject.mixtures
    );
  },
  combustionChambersReferenced: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => {
    return (
      rootGetters['combustionChambers/combustionChambers'] ===
      state.currentProject.combustionChambers
    );
  },
  calculationRequest: state => state.calculationRequest,
  calculationRunning: state => state.calculationRequest !== null,
};

const actions = {
  setCurrentProject({ commit, dispatch, getters }, project) {
    return new Promise((resolve, reject) => {
      ProjectUpgrader.upgrade(project)
        .then(response => {
          // distribute project parts to stores
          dispatch(
            'combustionChambers/setCombustionChambers',
            project.combustionChambers,
            { root: true },
          ).then(response => {
            dispatch(
              'combustionChambers/updateAll',
              {},
              { root: true },
            );
          });
          dispatch('mixtures/setMixtures', project.mixtures, {
            root: true,
          });
          dispatch('mixtures/setAmbient', project.ambient, {
            root: true,
          });
          dispatch('mixtures/setFlueGas', project.flueGas, {
            root: true,
          });
          dispatch('updateReferences', project);

          commit('setCurrentProject', project);
          dispatch('saveToLocalStorage', project);
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  updateReferences({ dispatch }, project) {
    // // updating references of mixture species

    // project.mixtures.forEach(mix => {
    //   dispatch('mixtures/updateReference', mix, { root: true });
    // });
    // updating references of combustionChamber inlet and outlet mixtures
    project.combustionChambers.forEach(chamber => {
      dispatch('combustionChambers/updateInletReferences', chamber, {
        root: true,
      });
      // dispatch('combustionChambers/updateOutletReferences', chamber, {
      //   root: true,
      // });
    });
  },
  updateProjectDiagram({ commit }, updatedProjectDiagram) {
    commit('setDiagram', updatedProjectDiagram);
  },
  saveToLocalStorage({ getters }, project) {
    localStorage.setItem('currentProject', JSON.stringify(project));
  },
  destroyCurrentProject({ commit }) {
    localStorage.removeItem('currentProject');
    commit('setCurrentProject', {});
  },
  initProject({ state, getters, rootGetters, dispatch }) {
    return new Promise((resolve, reject) => {
      try {
        var project = getters.emptyProject();
        dispatch('mixtures/initAmbient', '', {
          root: true,
        }).then(response => {
          project.ambient = rootGetters['mixtures/ambient'];
        });
        dispatch('mixtures/initFlueGas', '', {
          root: true,
        });
        project.flueGas = rootGetters['mixtures/flueGas'];
        dispatch('setCurrentProject', project);
        resolve({ data: { msg: 'Empty project created.' } });
      } catch {
        var error = {
          response: {
            data: { msg: 'Creating empty project failed.' },
          },
        };
        reject(error);
      }
    });
  },
  createProject(context, project) {
    return new Promise((resolve, reject) => {
      api
        .post('projects/create', { project })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          console.log(error);
        });
    });
  },
  deleteProject({ commit }, publicId) {
    if (
      confirm(
        'Are you sure you want to delete this project from database?',
      )
    ) {
      return new Promise((resolve, reject) => {
        api.delete(`projects/${publicId}`).then(
          response => {
            commit('deleteProject', publicId);
            resolve(response);
          },
          error => reject(error),
        );
      });
    }
  },
  setUpToDate({ commit }, upToDate) {
    commit('setUpToDate', upToDate);
  },
  solveProject({ dispatch, getters, commit }, { project }) {
    var assembledProject = getters['assembleProjectForSolver'](
      project,
    );
    return new Promise((resolve, reject) => {
      var calculationRequest = axios.CancelToken.source();
      commit('setCalculationRequest', calculationRequest);
      api
        .post(
          'solver/',
          { project: assembledProject },
          {
            cancelToken: calculationRequest.token,
          },
        )
        .then(
          response => {
            var disassembledProject = getters[
              'disassembleProjectFromSolver'
            ](response.data.project);
            dispatch('setCurrentProject', disassembledProject);
            resolve(response);
          },
          error => {
            reject(error);
          },
        )
        .finally(() => {
          commit('setCalculationRequest', null);
        });
    });
  },
  cancelRequest({ dispatch, getters, commit }, { message }) {
    var calculationRequest = getters['calculationRequest'].cancel(
      message,
    );
  },
  createExcelFile(
    { getters, rootGetters },
    { project, diagramUri, unit },
  ) {
    return new Promise((resolve, reject) => {
      var streamTypes = rootGetters['mixtures/streamTypes'];
      api
        .post(
          'projects/excel',
          { project, diagram: diagramUri, streamTypes, unit },
          {
            responseType: 'blob',
          },
        )
        .then(
          response => {
            var filename =
              'Report_' + getters['currentProjectFileName'] + '.xlsx';
            FileDownload(response.data, filename);
            resolve(response);
          },
          error => reject(error),
        );
    });
  },
};

const mutations = {
  setDiagram: (state, diagram) =>
    (state.currentProject.diagram = diagram),
  setCurrentProject: (state, project) =>
    (state.currentProject = project),
  setUpToDate: (state, upToDate) =>
    (state.currentProject.info.upToDate = upToDate),
  setCalculationRequest: (state, calculationRequest) =>
    (state.calculationRequest = calculationRequest),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
