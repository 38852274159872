import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

const User = () =>
  import(/* webpackChunkName: "user" */ '@/views/User.vue');
const UserLogin = () =>
  import(/* webpackChunkName: "userlogin" */ '@/views/UserLogin.vue');
const UserLogout = () =>
  import(
    /* webpackChunkName: "userlogout" */ '@/views/UserLogout.vue'
  );
const UserRegister = () =>
  import(
    /* webpackChunkName: "userregister" */ '@/views/UserRegister.vue'
  );
const UserConfirmEmail = () =>
  import(
    /* webpackChunkName: "userconfirmemail" */ '@/views/UserConfirmEmail.vue'
  );
const UserActivate = () =>
  import(
    /* webpackChunkName: "UserActivate" */ '@/views/UserActivate.vue'
  );
const UserForgotPassword = () =>
  import(
    /* webpackChunkName: "UserForgotPassword" */ '@/views/UserForgotPassword.vue'
  );
const UserResetPassword = () =>
  import(
    /* webpackChunkName: "UserResetPassword" */ '@/views/UserResetPassword.vue'
  );
const UserSettings = () =>
  import(
    /* webpackChunkName: "UserSettings" */ '@/views/UserSettings.vue'
  );
const Projects = () =>
  import(/* webpackChunkName: "Projects" */ '@/views/Projects.vue');
const Home = () =>
  import(/* webpackChunkName: "home" */ '@/views/Home.vue');
const General = () =>
  import(/* webpackChunkName: "General" */ '@/views/General.vue');
const About = () =>
  import(/* webpackChunkName: "about" */ '@/views/About.vue');
const Mixtures = () =>
  import(/* webpackChunkName: "Mixtures" */ '@/views/Mixtures.vue');
const CombustionChambers = () =>
  import(
    /* webpackChunkName: "CombustionChambers" */ '@/views/CombustionChambers.vue'
  );
const Calculation = () =>
  import(
    /* webpackChunkName: "Calculation" */ '@/views/Calculation.vue'
  );
const Results = () =>
  import(/* webpackChunkName: "Results" */ '@/views/Results.vue');
const Settings = () =>
  import(/* webpackChunkName: "Settings" */ '@/views/Settings.vue');
const TestArea = () =>
  import(/* webpackChunkName: "TestArea" */ '@/views/TestArea.vue');
const Admin = () =>
  import(/* webpackChunkName: "Admin" */ '@/views/Admin.vue');
const Users = () =>
  import(/* webpackChunkName: "Users" */ '@/views/Users.vue');
const MaterialDatabase = () =>
  import(
    /* webpackChunkName: "MaterialDatabase" */ '@/views/MaterialDatabase.vue'
  );
const MixtureDatabase = () =>
  import(
    /* webpackChunkName: "MixtureDatabase" */ '@/views/MixtureDatabase.vue'
  );
const Documentation = () =>
  import(
    /* webpackChunkName: "Documenation" */ '@/views/Documentation.vue'
  );
const DocumentationSolver = () =>
  import(
    /* webpackChunkName: "DocumenationSolver" */ '@/views/DocumentationSolver.vue'
  );
const NotFound = () =>
  import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/user',
    name: 'User',
    component: User,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: UserLogin,
        meta: { requiresLogin: false },
      },
      {
        path: 'logout',
        name: 'Logout',
        component: UserLogout,
        meta: { requiresLogin: true },
      },
      {
        path: 'register',
        name: 'Register',
        component: UserRegister,
        meta: { requiresLogin: false },
      },
      {
        path: 'confirmemail/:token',
        name: 'Confirm Email',
        component: UserConfirmEmail,
        meta: { requiresLogin: false },
      },
      {
        path: 'activate/:token',
        name: 'Activate User',
        component: UserActivate,
        meta: { requiresLogin: false },
      },
      {
        path: 'forgotpassword',
        name: 'Forgot Password',
        component: UserForgotPassword,
        meta: { requiresLogin: false },
      },
      {
        path: 'resetpassword/:token',
        name: 'Reset Password',
        component: UserResetPassword,
        meta: { requiresLogin: false },
      },
      {
        path: 'settings',
        name: 'User Settings',
        component: UserSettings,
        meta: { requiresLogin: true },
      },
    ],
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: { requiresLogin: true },
    children: [],
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresLogin: true },
    alias: ['/home'],
  },
  {
    path: '/general',
    name: 'General',
    component: General,
    meta: { requiresLogin: true },
  },
  { path: '/about', name: 'About', component: About },
  {
    path: '/mixtures',
    name: 'Mixtures',
    component: Mixtures,
    meta: { requiresLogin: true },
  },
  {
    path: '/combustionchambers',
    name: 'Combustion Chambers',
    component: CombustionChambers,
    meta: { requiresLogin: true },
  },
  {
    path: '/calculation',
    name: 'Calculation',
    component: Calculation,
    meta: { requiresLogin: true },
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
    meta: { requiresLogin: true },
  },
  {
    path: '/testarea',
    name: 'Test Area',
    component: TestArea,
    meta: { requiresLogin: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresLogin: true },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: { requiresLogin: true, requiresAdmin: true },
    children: [
      {
        path: 'users',
        name: 'Users',
        component: Users,
      },
    ],
  },
  {
    path: '/materialdatabase',
    name: 'Material Database',
    component: MaterialDatabase,
    meta: { requiresLogin: true },
  },
  {
    path: '/mixturedatabase',
    name: 'Mixture Database',
    component: MixtureDatabase,
    meta: { requiresLogin: true },
  },
  {
    path: '/docs',
    name: 'Documentation',
    component: Documentation,
    meta: { requiresLogin: true },
    children: [
      {
        path: 'solver',
        name: 'Solver Documentation',
        component: DocumentationSolver,
      },
    ],
  },
  { path: '*', name: 'Page Not Found', component: NotFound },
];

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }; // always strat scroll at top
  },
});
router.beforeEach((to, from, next) => {
  var loggedIn = store.getters.loggedIn;
  var allowNext = false;
  if (to.matched.some(m => m.meta.requiresLogin) && !loggedIn) {
    next({ name: 'Login' });
  } else if (to.name === 'Login' && loggedIn) {
    // do not allow logged in user to navigate to login view
    next({ name: 'Home' });
  } else if (from.name === 'Calculation') {
    var calculationRunning =
      store.getters['projects/calculationRunning'];
    if (calculationRunning) {
      store.dispatch('misc/setSnack', {
        text:
          'You cannot leave the calculation page while the calculation is still running. Wait for it to finish or abort it.',
        color: 'error',
        timeout: 5000,
      });
    } else {
      allowNext = true;
    }
  } else {
    allowNext = true;
  }
  if (allowNext) {
    if (loggedIn) {
      store.dispatch(
        'projects/saveToLocalStorage',
        store.getters['projects/currentProject'],
      );
    }
    next();
  }
});

export default router;
