<template>
  <div>
    <v-overlay :value="loading" :opacity="opacity" :z-index="zIndex">
      <v-container fluid>
        <v-row justify="center">
          <v-progress-circular indeterminate color="info">
          </v-progress-circular>
        </v-row>
        <v-row justify="center">
          <p class="my-4">{{ text }}</p>
        </v-row>
        <!-- <span>{{ text }}</span> -->
      </v-container>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    text: { type: String, default: '' },
    opacity: { type: Number, default: 0.9 },
    zIndex: { type: Number, default: 5 },
  },
};
</script>

<style></style>
