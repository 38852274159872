import store from '@/store';

class ProjectUpgrader {
  static upgrade(project) {
    return new Promise((resolve, reject) => {
      try {
        if (project === null) {
          reject({
            response: { data: { msg: 'No project defined.' } },
          });
          return;
        } else if (
          !('info' in project) ||
          !('version' in project.info)
        ) {
          reject({
            response: {
              data: {
                msg: 'Unable to find project version.',
                project,
              },
            },
          });
          return;
        }
        var version = project.info.version;
        var appVersion = store.getters['misc/version'];
        if (version < '1.0.0') {
          reject({
            response: {
              data: { msg: 'Project version too low to upgrade.' },
            },
          });
          return;
        } else if (version === appVersion) {
          resolve({
            data: {
              msg: `Project already up to date`,
              project: project,
            },
          });
          return;
        }
        if (project.info.version === '1.0.1') {
          ProjectUpgrader.upgradeTo_1_0_2(project);
        }
        if (project.info.version === '1.0.2') {
          ProjectUpgrader.upgradeTo_1_0_3(project);
        }
        if (project.info.version === '1.0.3') {
          ProjectUpgrader.upgradeTo_1_0_4(project);
        }
        if (project.info.version === '1.0.4') {
          ProjectUpgrader.upgradeTo_1_0_5(project);
        }
        if (project.info.version === '1.0.5') {
          ProjectUpgrader.upgradeTo_1_0_6(project);
        }
        if (project.info.version === '1.0.6') {
          ProjectUpgrader.upgradeTo_1_0_7(project);
        }
        if (project.info.version === '1.0.7') {
          ProjectUpgrader.upgradeTo_1_0_8(project);
        }
        if (project.info.version === '1.0.8') {
          ProjectUpgrader.upgradeTo_1_0_9(project);
        }
        if (project.info.version === '1.0.9') {
          ProjectUpgrader.upgradeTo_1_1_0(project);
        }
        if (project.info.version === '1.1.0') {
          ProjectUpgrader.upgradeTo_1_2_0(project);
        }
        if (project.info.version === '1.2.0') {
          ProjectUpgrader.upgradeTo_1_2_1(project);
        }
        if (project.info.version === '1.2.1') {
          ProjectUpgrader.upgradeTo_1_2_2(project);
        }
        if (project.info.version === '1.2.2') {
          ProjectUpgrader.upgradeTo_1_2_3(project);
        }
        if (project.info.version === '1.2.3') {
          ProjectUpgrader.upgradeTo_1_2_4(project);
        }
        resolve({
          data: {
            msg: `Project upgraded from ${version} to ${appVersion}`,
            project: project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: 'Error upgrading project', project },
          },
        });
      }
    });
  }
  static upgradeTo_1_0_2(project) {
    var newVersion = '1.0.2';
    return new Promise((resolve, reject) => {
      try {
        project.combustionChambers.forEach(chamber => {
          chamber.lambda = null;
        });
        var materials = store.getters['materials/materials'];
        var index = materials.findIndex(
          s => s.formula === 'Ar' && s.phase === 'g',
        );
        if (index !== -1) {
          var s = store.getters['materials/emptySpecies']();
          var mat = JSON.parse(JSON.stringify(materials[index]));
          Object.keys(mat)
            .filter(key => key in s)
            .forEach(key => (s[key] = mat[key]));
          project.ambient.dry.species.splice(
            3,
            0,
            JSON.parse(JSON.stringify(s)),
          );
          project.ambient.wet.species.splice(
            3,
            0,
            JSON.parse(JSON.stringify(s)),
          );
        } else {
          console.log('Argon not found in database materials');
        }

        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_0_3(project) {
    var newVersion = '1.0.3';
    return new Promise((resolve, reject) => {
      try {
        project.diagram = null; // reset diagram
        project.mixtures.forEach(mix => {
          mix.coupledToChamber = false;
          mix.coupledChamber = null;
        });
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_0_4(project) {
    var newVersion = '1.0.4';
    return new Promise((resolve, reject) => {
      try {
        // add new properties for combustion chambers
        project.combustionChambers.forEach(chamber => {
          chamber.residenceTime = 2.0;
          chamber.autoDimension = false;
          chamber.ldRatio = 4.0;
          chamber.lhRatio = 4.0;
          chamber.whRatio = 1.0;
          chamber.autoSurface = false;
          chamber.outletMixtures.forEach(outlet => {
            outlet.streamType = 'Flue Gas';
          });
        });
        // add new properties for mixtures
        var amb = project.ambient;
        amb.dry.flowRateInput = 'Volume Flow';
        if (!('rhoN' in amb.dry)) amb.dry.rhoN = amb.dry.rho;
        amb.dry.volumeFlow = amb.dry.massFlow / amb.dry.rho;
        amb.wet.flowRateInput = 'Volume Flow';
        if (!('rhoN' in amb.wet)) amb.wet.rhoN = amb.wet.rho;
        amb.wet.volumeFlow = amb.wet.massFlow / amb.wet.rho;
        var fg = project.flueGas;
        fg.flowRateInput = 'Volume Flow';
        if (!('rhoN' in fg)) fg.rhoN = fg.rho;
        fg.volumeFlow = fg.massFlow / fg.rho;
        project.mixtures.forEach(mix => {
          mix.flowRateInput =
            mix.phase === 'g' ? 'Volume Flow' : 'Mass Flow';
          if (!('rhoN' in mix)) mix.rhoN = mix.rho;
          mix.volumeFlow = mix.massFlow / mix.rho;
        });
        project.combustionChambers.forEach(chamber => {
          chamber.inletMixtures.forEach(mix => {
            mix.flowRateInput =
              mix.phase === 'g' ? 'Volume Flow' : 'Mass Flow';
            if (!('rhoN' in mix)) mix.rhoN = mix.rho;
            mix.volumeFlow = mix.massFlow / mix.rho;
          });
          chamber.outletMixtures.forEach(mix => {
            mix.flowRateInput =
              mix.phase === 'g' ? 'Volume Flow' : 'Mass Flow';
            if (!('rhoN' in mix)) mix.rhoN = mix.rho;
            mix.volumeFlow = mix.massFlow / mix.rho;
          });
        });
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_0_5(project) {
    var newVersion = '1.0.5';
    return new Promise((resolve, reject) => {
      try {
        project.info.loadCase = '';
        // add setpoints to combustion chambers
        project.combustionChambers.forEach(chamber => {
          // check if old lambda setpoint was active
          var index = chamber.inletMixtures.findIndex(
            mix => mix.coupledChamber === chamber.publicId,
          );
          var lambda = 2.0;
          if (index !== -1) {
            lambda = chamber.inletMixtures[index].lambda;
          }
          chamber.setpoints = [
            {
              type: 'lambda',
              text: 'Combustion Air Ratio',
              value: lambda,
            },
            {
              type: 'T',
              text: 'Temperature',
              value: 1773.15,
            },
            {
              type: 'O2',
              text: 'Flue Gas Rest O2',
              value: 0.05,
              inputFraction: 'Volume Fraction',
            },
          ];
          chamber.setpoint = chamber.setpoints[0];
        });
        // add NOxRate to project
        project.info.NOxRate = 0.001;
        // add pollutants to mixtures
        var pollutants = {
          NOx: {
            massFraction: 0.0,
            volumeFraction: 0.0,
            moleFraction: 0.0,
            rhoN: 2.053,
          },
        };
        project.mixtures.forEach(mix => {
          mix.pollutants = JSON.parse(JSON.stringify(pollutants));
          mix.fixedTemperature = false;
        });
        project.combustionChambers.forEach(chamber => {
          chamber.inletMixtures.forEach(mix => {
            mix.pollutants = JSON.parse(JSON.stringify(pollutants));
            mix.fixedTemperature = false;
          });
          chamber.outletMixtures.forEach(mix => {
            mix.pollutants = JSON.parse(JSON.stringify(pollutants));
            mix.fixedTemperature = false;
          });
        });
        project.flueGas.pollutants = JSON.parse(
          JSON.stringify(pollutants),
        );
        project.flueGas.fixedTemperature = false;
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_0_6(project) {
    var newVersion = '1.0.6';
    return new Promise((resolve, reject) => {
      try {
        // add normVolume flow to mixture
        project.mixtures.forEach(mix => {
          mix.normVolumeFlow = mix.volumeFlow;
          // get correct densities
          store
            .dispatch('mixtures/getMixture', {
              mixture: mix,
              inputFraction: 'Mass Fraction',
            })
            .then(response => {
              mix.rhoN = response.data.mixture.rhoN;
              mix.rho = response.data.mixture.rho;
              if (mix.flowRateInput == 'Volume Flow') {
                mix.massFlow = mix.normVolumeFlow * mix.rhoN;
              }
            });
        });
        project.combustionChambers.forEach(chamber => {
          chamber.inletMixtures.forEach(mix => {
            mix.normVolumeFlow = mix.volumeFlow;
          });
          chamber.outletMixtures.forEach(mix => {
            mix.normVolumeFlow = mix.volumeFlow;
          });
        });
        project.flueGas.normVolumeFlow = project.flueGas.volumeFlow;
        project.combustionChambers.forEach(chamber => {
          chamber.setpointActive = chamber.inletMixtures.some(
            mix => mix.coupledChamber === chamber.publicId,
          );
        });
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_0_7(project) {
    var newVersion = '1.0.7';
    return new Promise((resolve, reject) => {
      try {
        // add stoichiometry to setpoints
        project.combustionChambers.forEach(chamber => {
          chamber.setpoints[1].stoichiometry = 'overstoichiometric';
          if (chamber.setpoint.type === 'T') {
            chamber.setpoint.stoichiometry = 'overstoichiometric';
          }
        });
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_0_8(project) {
    var newVersion = '1.0.8';
    return new Promise((resolve, reject) => {
      try {
        // add solver settings to project
        project.solverSettings = {
          numberOfIterations: 10,
          convergence: 1e-4,
        };
        project.combustionChambers.forEach(chamber => {
          chamber.solverSettings = {
            setpoint: {
              numberOfIterations: 20,
              convergence: 1e-2,
            },
            equiCalcTemperature: {
              numberOfIterations: 50,
              convergence: 1e-2,
              alpha: 1.0,
            },
            equiCalcSpecies: {
              numberOfIterations: 500,
              convergence: 1e-8,
            },
          };
        });
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_0_9(project) {
    var newVersion = '1.0.9';
    return new Promise((resolve, reject) => {
      try {
        // add solver stats to project
        project.solverStats = {
          isConverged: false,
          log: { in: [], out: [], delta: [], rel: [] },
        };
        project.combustionChambers.forEach(chamber => {
          chamber.solverStats = { isConverged: false, log: [] };
        });
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_1_0(project) {
    var newVersion = '1.1.0';
    return new Promise((resolve, reject) => {
      try {
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_2_0(project) {
    var newVersion = '1.2.0';
    return new Promise((resolve, reject) => {
      try {
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_2_1(project) {
    var newVersion = '1.2.1';
    return new Promise((resolve, reject) => {
      try {
        project.combustionChambers.forEach(chamber => {
          var areaSpecificHeatLoss = chamber.areaSpecificHeatLoss;
          var heatLoss = chamber.heatLoss;
          chamber.heatLoss = {
            method: 'Area Specific',
            areaSpecificHeatLoss: areaSpecificHeatLoss,
            layers: [{ thickness: 0.1, lambda: 2.0 }],
            emissivityWall: 0.8,
            alphaWall: 20.0,
            heatLoss: heatLoss,
          };
          chamber.lambda = null;
        });
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_2_2(project) {
    var newVersion = '1.2.2';
    return new Promise((resolve, reject) => {
      try {
        // nothing to change
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_2_3(project) {
    var newVersion = '1.2.3';
    return new Promise((resolve, reject) => {
      try {
        // nothing to change
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
  static upgradeTo_1_2_4(project) {
    var newVersion = '1.2.4';
    return new Promise((resolve, reject) => {
      try {
        project.lambda = 0.0;
        project.lambdaWithSetpoint = 0.0;
        project.info.version = newVersion;
        resolve({
          data: {
            msg: `Successfully upgraded to ${newVersion}`,
            project,
          },
        });
      } catch {
        reject({
          response: {
            data: { msg: `Error while upgrading to ${newVersion}` },
          },
        });
      }
    });
  }
}

export default ProjectUpgrader;
