import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import { Plotly } from 'vue-plotly';
import VueMathjax from 'vue-mathjax';
import moment from 'moment';
import LoadScript from 'vue-plugin-load-script';
import api from './plugins/api';
import NumericTextField from '@/components/misc/NumericTextField';
import NumericTextFieldUnit from '@/components/misc/NumericTextFieldUnit';

Vue.use(LoadScript);

Vue.loadScript(
  'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-AMS_HTML',
);
Vue.use(VueMathjax);

Vue.filter('formatDate', function(value) {
  if (value) {
    // return moment(String(value)).format('DD.MM.YYYY HH:mm');
    var dateUTC = moment.utc(value); // get object in UTC time zone
    var dateLocal = dateUTC.local(); // get object in local time zone
    return dateLocal.format('DD.MM.YYYY HH:mm:ss'); // return formatted local time
  }
});

Vue.config.productionTip = false;

Vue.component('plotly', Plotly);
Vue.component('v-numeric-text-field', NumericTextField);
Vue.component('v-numeric-text-field-unit', NumericTextFieldUnit);
Vue.prototype.$api = api;

var themeName = localStorage.getItem('theme');
store.dispatch('misc/changeTheme', themeName, { root: true });

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');
