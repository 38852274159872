<template>
  <v-app id="inspire">
    <Navbar></Navbar>
    <v-main class="mb-4 background">
      <OnScrollToTop />
      <router-view />
    </v-main>
    <Footer></Footer>
    <Snackbar />
    <LoadingOverlay :loading="loading.status" :text="loading.text" />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import Snackbar from '@/components/misc/Snackbar';
import LoadingOverlay from '@/components/misc/LoadingOverlay';
import OnScrollToTop from '@/components/misc/OnScrollToTop';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    Snackbar,
    LoadingOverlay,
    OnScrollToTop,
  },
  props: {},
  methods: {},
  created() {
    this.$store
      .dispatch('misc/initApp', this.$route)
      .then(response => {
        this.$store.dispatch('misc/setSnack', {
          text: 'Welcome!',
          color: 'success',
        });
      })
      .catch(error => {
        this.$store.dispatch('misc/setSnack', {
          text: error.response.data.msg,
          color: 'error',
        });
        console.log(error);
      })
      .finally(() => this.$store.dispatch('misc/setLoading', false));
  },
  computed: {
    ...mapGetters('misc', ['loading']),
  },
};
</script>
