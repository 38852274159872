import api from '@/plugins/api';

const namespaced = true;

const state = {
  combustionChambers: [],
  emptyCombustionChamber: {
    publicId: -1,
    name: '',
    shape: 'cylindrical',
    orientation: 'vertical',
    flowDirection: 'top to bottom',
    autoDimension: false,
    residenceTime: 2.0,
    ldRatio: 4.0,
    lhRatio: 4.0,
    whRatio: 1.0,
    length: 10,
    diameter: 2,
    width: 1,
    height: 1,
    wallThickness: 0.1,
    autoSurface: true,
    surface: 48,
    p: 1e5,
    heatLoss: {},
    // areaSpecificHeatLoss: 100,
    // heatLoss: 4000,
    effectiveHeatFlow: 0,
    setpointActive: false,
    setpoint: {},
    setpoints: null,
    solverSettings: {},
    solverStats: { isConverged: false, log: [] },
    lambda: null,
    inletMixtures: [],
    outletMixtures: [],
  },
  shapes: ['cylindrical', 'rectangular'],
  orientations: ['horizontal', 'vertical'],
  flowDirections: {
    horizontal: ['left to right', 'right to left'],
    vertical: ['top to bottom', 'bottom to top'],
  },
  heatLossMethods: ['Adiabatic', 'Area Specific', 'Wall Layers'],
  setpoints: [
    {
      type: 'lambda',
      text: 'Combustion Air Ratio',
      value: 2.0,
    },
    {
      type: 'T',
      text: 'Temperature',
      value: 1273.15,
      stoichiometry: 'overstoichiometric',
    },
    {
      type: 'O2',
      text: 'Rest O2',
      value: 0.05,
      inputFraction: 'Volume Fraction',
    },
  ],
  emptySolverSettings: {
    setpoint: {
      numberOfIterations: 50,
      convergence: 1e-4,
    },
    equiCalcTemperature: {
      numberOfIterations: 50,
      convergence: 1e-2,
      alpha: 1.0,
    },
    equiCalcSpecies: {
      numberOfIterations: 500,
      convergence: 1e-8,
    },
  },
  emptyHeatLoss: {
    method: 'Adiabatic',
    areaSpecificHeatLoss: 100,
    layers: [],
    emissivityWall: 0.8,
    alphaWall: 20.0,
    heatLoss: 4000,
  },
  emptyWallLayer: {
    thickness: 0.1,
    lambda: 2.0,
  },
};

const getters = {
  combustionChambers: state => state.combustionChambers,
  emptyCombustionChamber: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => () => {
    let newCombustionChamber = JSON.parse(
      JSON.stringify(state.emptyCombustionChamber),
    );
    newCombustionChamber.outletMixtures.push(
      rootGetters['mixtures/emptyOutlet'](),
    );
    newCombustionChamber.heatLoss = getters.emptyHeatLoss();
    newCombustionChamber.setpoints = getters.setpoints();
    newCombustionChamber.setpoint = newCombustionChamber.setpoints[0];
    newCombustionChamber.solverSettings = getters.emptySolverSettings();
    return newCombustionChamber;
  }, // now a function!
  assembleCombustionChamber: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => chamber => {
    chamber = JSON.parse(JSON.stringify(chamber));
    chamber.inletMixtures = chamber.inletMixtures.map(mix => {
      return rootGetters['mixtures/assembleMixture'](mix);
    });
    chamber.outletMixtures = chamber.outletMixtures.map(mix => {
      return rootGetters['mixtures/assembleMixture'](mix);
    });
    return chamber;
  },
  disassembleCombustionChamber: (
    state,
    getters,
    rootState,
    rootGetters,
  ) => chamber => {
    // chamber = JSON.parse(JSON.stringify(chamber));
    chamber.inletMixtures = chamber.inletMixtures.map(mix => {
      return rootGetters['mixtures/disassembleMixture'](mix);
    });
    chamber.outletMixtures = chamber.outletMixtures.map(mix => {
      return rootGetters['mixtures/disassembleMixture'](mix);
    });
    chamber.setpoint = chamber.setpoints.find(
      sp => sp.type === chamber.setpoint.type,
    );
    return chamber;
  },
  shapes: state => state.shapes,
  orientations: state => state.orientations,
  flowDirections: state => state.flowDirections,
  heatLossMethods: state => state.heatLossMethods,
  setpoints: state => () =>
    JSON.parse(JSON.stringify(state.setpoints)),
  inletMixtures: state => {
    var inletMixtures = [];
    state.combustionChambers.forEach(chamber => {
      inletMixtures.push(...chamber.inletMixtures);
    });
    return inletMixtures;
  },
  outletMixtures: state => {
    var outletMixtures = [];
    state.combustionChambers.forEach(chamber => {
      outletMixtures.push(...chamber.outletMixtures);
    });
    return outletMixtures;
  },
  globalInletMixtures: (state, getters) => {
    var inletMixtures = getters.inletMixtures;
    var outletMixtures = getters.outletMixtures;
    var globalInletMixtures = inletMixtures.filter(
      inlet => !outletMixtures.includes(inlet),
    );
    return globalInletMixtures;
  },
  globalOutletMixtures: (state, getters) => {
    var inletMixtures = getters.inletMixtures;
    var outletMixtures = getters.outletMixtures;
    var globalOutletMixtures = outletMixtures.filter(
      outlet => !inletMixtures.includes(outlet),
    );
    return globalOutletMixtures;
  },
  uniqueMixtures: state => {
    var uniqueMixtures = [];
    state.combustionChambers.forEach(chamber => {
      chamber.inletMixtures.forEach(mix => {
        if (
          uniqueMixtures.filter(m => m.publicId === mix.publicId)
            .length === 0
        ) {
          uniqueMixtures.push(mix);
        }
      });
      chamber.outletMixtures.forEach(mix => {
        if (
          uniqueMixtures.filter(m => m.publicId === mix.publicId)
            .length === 0
        ) {
          uniqueMixtures.push(mix);
        }
      });
    });
    uniqueMixtures.sort((a, b) =>
      a.streamId > b.streamId ? 1 : b.streamId > a.streamId ? -1 : 0,
    );
    return uniqueMixtures;
  },
  flueGases: (state, getters) => {
    return getters.uniqueMixtures.filter(
      mix => mix.streamType === 'Flue Gas',
    );
  },
  emptySolverSettings: state => () => {
    return JSON.parse(JSON.stringify(state.emptySolverSettings));
  },
  emptyHeatLoss: (state, getters) => () => {
    var emptyHeatLoss = JSON.parse(
      JSON.stringify(state.emptyHeatLoss),
    );
    emptyHeatLoss.layers.push(getters.emptyWallLayer());
    return emptyHeatLoss;
  },
  emptyWallLayer: state => () => {
    return JSON.parse(JSON.stringify(state.emptyWallLayer));
  },
};

const actions = {
  setCombustionChambers({ commit }, combustionChambers) {
    commit('setCombustionChambers', combustionChambers);
  },
  addCombustionChamber({ dispatch, commit }, combustionChamber) {
    return new Promise((resolve, reject) => {
      try {
        Array.isArray(combustionChamber)
          ? commit('newCombustionChambers', combustionChamber)
          : commit('newCombustionChamber', combustionChamber);
        dispatch('calcMassFlows');
        dispatch('projects/setUpToDate', false, { root: true });
        resolve({ data: { msg: 'Combustion chamber added!' } });
      } catch {
        reject({
          response: {
            data: { msg: 'Failed to add combustion chamber...' },
          },
        });
      }
    });
  },
  deleteCombustionChamber(
    { commit, getters, rootGetters },
    publicId,
  ) {
    if (
      confirm(
        'Are you sure you want to delete this combustion Chamber?',
      )
    ) {
      // decouple mixture if there is a mixture coupled to chamber
      var mixtures = rootGetters['mixtures/mixtures'];
      mixtures.forEach(mix => {
        if (mix.coupledToChamber && mix.coupledChamber === publicId) {
          mix.coupledChamber = null;
          mix.coupledToChamber = false;
        }
      });
      // remove outlets (if they are used as inlets in other chambers)
      var chambers = getters['combustionChambers'];
      var chamberToDelete = chambers.find(
        chamber => chamber.publicId === publicId,
      );
      chamberToDelete.outletMixtures.forEach(outletMix => {
        chambers.forEach(chamber => {
          const index = chamber.inletMixtures.findIndex(
            mix => mix.publicId === outletMix.publicId,
          );
          if (index !== -1) {
            chamber.inletMixtures.splice(index, 1);
          }
        });
      });
      commit('removeCombustionChamber', publicId);
    }
  },
  deleteAllCombustionChambers({ commit }) {
    if (
      confirm(
        'Are you sure you want to delete all (!!!) combustion chambers from database?',
      )
    ) {
      commit('removeAllCombustionChambers');
    }
  },
  updateAll({ dispatch }) {
    dispatch('calcMassFlows').then(response => {
      dispatch('calcLambdas', false);
    });
  },
  updateCombustionChamber(
    { commit, dispatch },
    updatedCombustionChamber,
  ) {
    return new Promise((resolve, reject) => {
      if (
        confirm(
          'Are you sure you want to update this combustion chamber?',
        )
      ) {
        commit('updateCombustionChamber', updatedCombustionChamber);
        dispatch('updateOutletReferences', updatedCombustionChamber);
        dispatch('updateInletReferences', updatedCombustionChamber);
        dispatch('calcMassFlows');
        dispatch('projects/setUpToDate', false, { root: true });
        resolve({ data: { msg: 'Combustion Chamber updated!' } });
      } else {
        reject({
          response: {
            data: {
              msg: 'Updating Combustion Chamber aborted by user.',
            },
          },
        });
      }
    });
  },
  updateInletReferences({ getters, rootGetters }, chamber) {
    var mixtures = rootGetters['mixtures/mixtures'];
    var chambers = getters.combustionChambers;
    var i;
    var publicId;
    var index;
    // update references of inletMixtures
    for (i = 0; i < chamber.inletMixtures.length; i++) {
      publicId = chamber.inletMixtures[i].publicId;
      index = mixtures.findIndex(mix => mix.publicId === publicId);
      if (index !== -1) {
        // console.log(
        //   'Referencing mixture from mixtures array to inletMix',
        // );
        if (chamber.inletMixtures[i].coupledToChamber) {
          Object.assign(mixtures[index], chamber.inletMixtures[i]);
        }
        chamber.inletMixtures[i] = mixtures[index];
      } else {
        // try to find inletMix in chamber outlets
        index = chamber.outletMixtures.findIndex(
          mix => mix.publicId === publicId,
        );
        if (index !== -1) {
          // console.log(
          //   'Referencing mixture from own outletMixtures to inletMix',
          // );
          chamber.inletMixtures[i] = chamber.outletMixtures[index];
        } else {
          // try to find inletMix in other chambers outlets
          var found = false;
          for (var j = 0; j < chambers.length; j++) {
            index = chambers[j].outletMixtures.findIndex(
              mix => mix.publicId === publicId,
            );
            if (index !== -1) {
              // console.log(
              //   "Referencing mixture from other chamber's outletMixtures to inletMix",
              // );
              chamber.inletMixtures[i] =
                chambers[j].outletMixtures[index];
              found = true;
              break;
            }
          }
          if (!found) {
            console.log(
              'Could not reference inlet mix to any other mixture',
            );
            // console.log(chamber.inletMixtures[i]);
          }
        }
      }
    }
    // update project mixtures coupledChamber status
    if (!chamber.setpointActive) {
      mixtures.forEach(mix => {
        if (mix.coupledChamber === chamber.publicId) {
          mix.coupledChamber = null;
          mix.coupledToChamber = false;
        }
      });
    }
  },
  updateOutletReferences({ getters }, chamber) {
    // update references of outletMixtures
    var chambers = getters.combustionChambers;
    var i;
    var j;
    var publicId;
    var index;
    for (i = 0; i < chamber.outletMixtures.length; i++) {
      var outletMix = chamber.outletMixtures[i];
      publicId = chamber.outletMixtures[i].publicId;
      for (j = 0; j < chambers.length; j++) {
        index = chambers[j].inletMixtures.findIndex(
          inletMix => inletMix.publicId === publicId,
        );
        if (index !== -1) {
          chambers[j].inletMixtures[index] = outletMix;
        }
      }
    }
  },
  calcMassFlows({ dispatch, getters }) {
    // TODO: further development needed:
    // - criteria for abortion not yet implemented
    // - check if complex recirculations work correctly
    const maxCounter = 10;
    var counter = 0;
    var chambers = getters['combustionChambers'];
    do {
      // console.log(`${counter + 1}/${maxCounter}`);
      chambers.forEach(chamber => {
        // console.log(chamber.name);
        dispatch('calcChamberLambda', {
          chamber,
          includeCoupledMixture: false,
        });
        if (chamber.inletMixtures.some(mix => mix.coupledToChamber)) {
          dispatch('calcSetpointMassFlow', chamber);
        }
        // console.log(chamber.name, chamber.lambda);
        dispatch('calcChamberMassFlows', chamber);
      });
      counter += 1;
    } while (counter < maxCounter);
  },
  calcChamberMassFlows(context, chamber) {
    const maxDelta = 1e-8;
    const maxCounter = 100;
    var counter = 0;
    var totalInletMassFlow = chamber.inletMixtures.reduce(
      (pv, cv) => pv + cv.massFlow,
      0,
    );
    if (totalInletMassFlow === 0.0) {
      // break loop if inlets not yet defined
      chamber.outletMixtures.forEach(mix => {
        mix.massFlow = 0.0;
      });
      return;
    }
    if (chamber.outletMixtures.length === 0) {
      return;
    }
    do {
      // adapt outlet mass flows
      var totalOutletMassFlow = 0.0;
      var sumFractions = chamber.outletMixtures.reduce(
        (pv, cv) => pv + cv.fraction,
        0,
      );
      chamber.outletMixtures.forEach(mix => {
        mix.massFlow =
          (mix.fraction * totalInletMassFlow) / sumFractions;
        // console.log(mix.name, `${mix.massFlow * 3600} kg/h`);
        totalOutletMassFlow += mix.massFlow;
      });
      totalInletMassFlow = chamber.inletMixtures.reduce(
        (pv, cv) => pv + cv.massFlow,
        0,
      );
      var delta = totalInletMassFlow - totalOutletMassFlow;
      var rel_delta = Math.abs(delta / totalInletMassFlow);
      // console.log(counter, rel_delta);
      counter += 1;
    } while (rel_delta > maxDelta && counter < maxCounter);
    // set O2 demand to all outlets
    var O2Demand =
      chamber.inletMixtures.reduce(
        (pv, cv) => pv + cv.massFlow * cv.O2Demand,
        0,
      ) / totalInletMassFlow;
    chamber.outletMixtures.forEach(mix => (mix.O2Demand = O2Demand));
  },
  calcLambdas({ getters, dispatch }, includeCoupledMixture) {
    var chambers = getters.combustionChambers;
    chambers.forEach(chamber =>
      dispatch('calcChamberLambda', {
        chamber,
        includeCoupledMixture,
      }),
    );
  },
  calcChamberLambda(context, { chamber, includeCoupledMixture }) {
    var massFlowOxCurrent = 0.0;
    var massFlowOxStoich = 0.0;
    chamber.inletMixtures.forEach(mix => {
      if (!mix.coupledToChamber || includeCoupledMixture) {
        mix.O2Demand > 0
          ? (massFlowOxStoich += mix.massFlow * mix.O2Demand)
          : (massFlowOxCurrent -= mix.massFlow * mix.O2Demand);
      }
    });
    chamber.lambda = massFlowOxCurrent / massFlowOxStoich;
    // console.log(chamber.lambda);
  },
  calcSetpointMassFlow(context, chamber) {
    return new Promise((resolve, reject) => {
      // check if there is a coupled air mixture
      const index = chamber.inletMixtures.findIndex(
        mix =>
          mix.coupledToChamber &&
          mix.coupledChamber == chamber.publicId,
      );
      if (index === -1) {
        reject({
          response: {
            data: { msg: 'No coupled air mixture defined' },
          },
        });
        return;
      }
      var coupledMix = chamber.inletMixtures[index];

      switch (chamber.setpoint.type) {
        case 'lambda':
          context
            .dispatch('calcSetpointMassFlowLambda', {
              chamber,
              coupledMix,
            })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
          break;
        case 'T':
          // context
          //   .dispatch('calcSetpointMassFlowT', {
          //     chamber,
          //     coupledMix,
          //   })
          //   .then(response => {
          //     resolve(response);
          //   })
          //   .catch(error => {
          //     reject(error);
          //   });
          break;
        case 'O2':
          // context
          //   .dispatch('calcSetpointMassFlowO2', {
          //     chamber,
          //     coupledMix,
          //   })
          //   .then(response => {
          //     resolve(response);
          //   })
          //   .catch(error => {
          //     reject(error);
          //   });
          break;
      }
    });
  },
  calcSetpointMassFlowLambda({ dispatch }, { chamber, coupledMix }) {
    return new Promise((resolve, reject) => {
      var o2Demand = coupledMix.O2Demand;
      if (o2Demand === 0) {
        // coupledMix cannot be used for setpoint control, because it has no positive or negative o2 demand
        reject({
          response: {
            data: {
              msg: 'Setpoint combustion air ratio is too low',
            },
          },
        });
        return;
      }
      // check if setpoint lambda is greater than chamber lambda
      coupledMix.lambda = chamber.setpoint.value;
      var includeCoupledMixture = false;
      dispatch('calcChamberLambda', {
        chamber,
        includeCoupledMixture,
      }).then(() => {
        // console.log(`coupledMix.o2Demand: ${o2Demand}`);
        var massFlowOxCurrent = chamber.inletMixtures.reduce(
          (pv, cv) =>
            pv -
            (cv.coupledToChamber
              ? 0.0
              : cv.massFlow * Math.min(0, cv.O2Demand)),
          0,
        );
        var massFlowOxStoich = chamber.inletMixtures.reduce(
          (pv, cv) =>
            pv +
            (cv.coupledToChamber
              ? 0.0
              : cv.massFlow * Math.max(0, cv.O2Demand)),
          0,
        );
        if (o2Demand < 0) {
          // coupledMix is oxidizer
          if (chamber.lambda >= coupledMix.lambda) {
            coupledMix.massFlow = 0.0;
            coupledMix.normVolumeFlow = 0.0;
            reject({
              response: {
                data: {
                  msg: 'Setpoint combustion air ratio is too low',
                },
              },
            });
            return;
          }
          var massFlowOxControl =
            coupledMix.lambda * massFlowOxStoich - massFlowOxCurrent;
          coupledMix.massFlow =
            massFlowOxControl / Math.abs(coupledMix.O2Demand);
          coupledMix.normVolumeFlow =
            coupledMix.massFlow / coupledMix.rhoN;
        } else if (o2Demand > 0) {
          // coupledMix is fuel
          if (chamber.lambda <= coupledMix.lambda) {
            coupledMix.massFlow = 0.0;
            coupledMix.normVolumeFlow = 0.0;
            reject({
              response: {
                data: {
                  msg: 'Setpoint combustion air ratio is too high',
                },
              },
            });
            return;
          }
          var massFlowFuelControl =
            massFlowOxCurrent / coupledMix.lambda - massFlowOxStoich;
          coupledMix.massFlow =
            massFlowFuelControl / coupledMix.O2Demand;
          coupledMix.normVolumeFlow =
            coupledMix.massFlow / coupledMix.rhoN;
          // console.log(massFlowFuelControl);
        }
      });
    });
  },
  calcSetpointMassFlowT({ dispatch }, { chamber, coupledMix }) {
    return new Promise((resolve, reject) => {
      console.log('T Setpoint mass flow reached');
      // dispatch('solveCombustionChamber', chamber).then(response => {
      //   var chamberCalculated = response.data.chamber;
      //   var newMassFlow = chamberCalculated.inletMixtures.find(
      //     mix => mix.publicId == coupledMix.publicId,
      //   ).massFlow;
      //   coupledMix.massFlow = newMassFlow;
      //   coupledMix.normVolumeFlow = newMassFlow / coupledMix.rhoN;
      //   console.log('Chambe solver finished');
      // });
    });
  },
  calcSetpointMassFlowO2(context, { chamber, coupledMix }) {
    return new Promise((resolve, reject) => {
      console.log('O2 Setpoint mass flow reached');
    });
  },
  solveCombustionChamber({ rootGetters, getters }, chamber) {
    var assembledChamber = getters['assembleCombustionChamber'](
      chamber,
    );
    var flueGas = rootGetters['mixtures/assembleMixture'](
      rootGetters['mixtures/flueGas'],
    );
    return new Promise((resolve, reject) => {
      api
        .post('solver/chamber', {
          chamber: assembledChamber,
          flueGas: flueGas,
        })
        .then(
          response => {
            console.log(response.data);
            response.data.chamber = getters[
              'disassembleCombustionChamber'
            ](response.data.chamber);
            resolve(response);
          },
          error => {
            console.log(error.response);
            reject(error);
          },
        );
    });
  },
};
const mutations = {
  setCombustionChambers: (state, combustionChambers) =>
    (state.combustionChambers = combustionChambers),
  newCombustionChamber: (state, newCombustionChamber) =>
    state.combustionChambers.push(newCombustionChamber),
  newCombustionChambers: (state, newCombustionChambers) =>
    state.combustionChambers.push(...newCombustionChambers),
  updateCombustionChamber: (state, updatedCombustionChamber) => {
    const index = state.combustionChambers.findIndex(
      combustionChamber =>
        combustionChamber.publicId ===
        updatedCombustionChamber.publicId,
    );
    if (index != -1) {
      state.combustionChambers.splice(
        index,
        1,
        updatedCombustionChamber,
      );
    }
  },
  removeCombustionChamber: (state, publicId) => {
    const index = state.combustionChambers.findIndex(
      chamber => chamber.publicId === publicId,
    );
    if (index !== -1) {
      state.combustionChambers.splice(index, 1);
    }
  },
  removeAllCombustionChambers: state =>
    (state.combustionChambers = []),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
