<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout">
    {{ message }}
    <v-spacer></v-spacer>
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" fab small @click="show = false">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      timeout: 5000,
      standardTimeout: 5000,
      show: false,
      message: '',
      color: 'info',
    };
  },
  created: function() {
    this.$store.watch(
      state => state.misc.snack.text,
      () => {
        const msg = this.$store.state.misc.snack.text;
        if (msg !== '') {
          this.show = true;
          this.message = this.$store.state.misc.snack.text;
          this.color = this.$store.state.misc.snack.color;
          var newTimeout = this.$store.state.misc.snack.timeout;
          this.timeout =
            newTimeout === undefined
              ? this.standardTimeout
              : newTimeout;
          this.$store.commit('misc/setSnack', {
            color: 'success',
            text: '',
          });
        }
      },
    );
  },
};
</script>
