import api from '@/plugins/api';
import Vuetify from '@/plugins/vuetify';

const state = {
  users: [],
  accessToken: localStorage.getItem('accessToken') || null,
  tokenValid: true,
  currentUser: null,
  emptyUser: {
    publicId: -1,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordRepeat: '',
    emailConfirmed: false,
    activated: false,
    admin: false,
    settings: { theme: 'light' },
  },
};

const getters = {
  users: state => state.users,
  loggedIn: state => state.accessToken != null && true,
  admin: state =>
    state.currentUser !== null ? state.currentUser.admin : false,
  currentUser: state => state.currentUser,
  accessToken: state => state.accessToken,
  theme: state => state.currentUser.settings.theme,
  emptyUser: state => () => {
    let newUser = JSON.parse(JSON.stringify(state.emptyUser));
    return newUser;
  }, // now a function!
  iconColor: state =>
    Vuetify.framework.theme.dark ? 'white' : 'black',
  tokenValid: state => state.tokenValid,
};

const actions = {
  loginUser({ commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      dispatch('misc/setLoading', {
        status: true,
        text: 'Logging in',
      });
      api
        .post('auth/login', credentials)
        .then(
          response => {
            var accessToken = response.data.accessToken;
            var theme = response.data.user.settings.theme;
            console.log(theme);
            localStorage.setItem('theme', theme);
            var userProject = response.data.user.currentProject;
            commit('projects/setCurrentProject', userProject, {
              root: true,
            });
            commit('setAccessToken', accessToken);
            commit('setCurrentUser', response.data.user);
            resolve(response);
          },
          error => reject(error),
        )
        .finally(() => dispatch('misc/setLoading', false));
    });
  },
  logoutUser({ commit, getters, dispatch }, { force = false }) {
    return new Promise((resolve, reject) => {
      api
        .get('auth/logout')
        .then(response => resolve(response))
        .catch(error => reject(error))
        .finally(() => {
          commit('setCurrentUser', getters.emptyUser());
          commit('destroyAccessToken');
          dispatch(
            'projects/destroyCurrentProject',
            {},
            { root: true },
          );
          dispatch('materials/destroyMaterials', {}, { root: true });
          dispatch(
            'mixtures/destroyDatabaseMixtures',
            {},
            { root: true },
          );
        });
    });
  },
  getUser(context) {
    return new Promise((resolve, reject) => {
      api.get('auth/').then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
  checkToken(context) {
    return new Promise((resolve, reject) => {
      api
        .get('auth/token')
        .then(response => {
          console.log(response);
          resolve(response);
        })
        .catch(error => {
          context.commit('destroyAccessToken');
          context.commit(
            'misc/setSnack',
            { text: error.response.data.msg, color: 'error' },
            { root: true },
          );
          reject(error);
        });
    });
  },
  getUserSettings({ commit, getters, dispatch }) {
    return new Promise((resolve, reject) => {
      api.get('auth/user/settings').then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
  updateUserSettings({ getters, commit }, updatedUserSettings) {
    return new Promise((resolve, reject) => {
      api
        .put(`auth/user/settings`, { settings: updatedUserSettings })
        .then(
          response => {
            commit('setCurrentUser', response.data.user);
            var theme = response.data.user.settings.theme;
            localStorage.setItem('theme', theme);
            resolve(response);
          },
          error => {
            reject(error);
          },
        );
    });
  },
  uploadCurrentProject(context, project) {
    return new Promise((resolve, reject) => {
      api.put(`auth/user/currentProject`, { project: project }).then(
        response => {
          console.log(response.data.msg);
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
  downloadCurrentProject({ dispatch }) {
    return new Promise((resolve, reject) => {
      api
        .get(`auth/user/currentProject`)
        .then(response => {
          dispatch(
            'projects/setCurrentProject',
            response.data.project,
            { root: true },
          )
            .then(() => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateUser({ getters }, updatedUser) {
    return new Promise((resolve, reject) => {
      api
        .put(`auth/${updatedUser.publicId}`, { user: updatedUser })
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          },
        );
    });
  },
  getActivateTokenValid({ getters }, token) {
    console.log(token);
    return new Promise((resolve, reject) => {
      api.get(`auth/activate/${token}`).then(
        response => {
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  activateUserWithToken({ getters }, token) {
    console.log(token);
    return new Promise((resolve, reject) => {
      api.put(`auth/activate/${token}`).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },

  getAllUsers({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('auth/users').then(
        response => {
          commit('setUsers', response.data.users);
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  registerUser({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      api.post('auth/register', credentials).then(
        response => {
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  confirmEmail(context, token) {
    return new Promise((resolve, reject) => {
      api.get(`auth/confirm/${token}`).then(
        response => {
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  createUser({ commit, getters }, user) {
    return new Promise((resolve, reject) => {
      api.post('auth/create', user).then(
        response => {
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  deleteUser({ commit }, publicId) {
    return new Promise((resolve, reject) => {
      if (
        confirm(
          'Are you sure you want to delete this Account permanently?',
        )
      ) {
        api.delete(`auth/${publicId}`).then(
          response => {
            resolve(response);
            commit('deleteUser', publicId);
          },
          error => {
            reject(error);
          },
        );
      } else {
        reject({
          response: {
            data: { message: 'User deletion aborted by user.' },
          },
        });
      }
    });
  },
  forgotPassword({ getters }, user) {
    return new Promise((resolve, reject) => {
      api.post('auth/forgotPassword', user).then(
        response => {
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  resetPassword({ getters }, { user, token }) {
    return new Promise((resolve, reject) => {
      api.put(`auth/resetPassword/${token}`, user).then(
        response => {
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  getResetPasswordTokenValid({ getters }, token) {
    return new Promise((resolve, reject) => {
      api.get(`auth/resetPassword/${token}`).then(
        response => {
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
};

const mutations = {
  setUsers: (state, users) => (state.users = users),
  deleteUser: (state, publicId) =>
    (state.users = state.users.filter(
      user => user.publicId != publicId,
    )),
  setAccessToken: (state, token) => {
    localStorage.setItem('accessToken', token);
    state.accessToken = token;
  },
  destroyAccessToken: state => {
    localStorage.removeItem('accessToken');
    state.accessToken = null;
  },
  setCurrentUser(state, user) {
    state.currentUser = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
