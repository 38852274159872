<template>
  <v-numeric-text-field
    v-bind="$attrs"
    v-model="input"
    :suffix="settingNew.unit"
    :label="label"
    :precision="settingNew.precision"
    :integer="integer"
    v-on:lockUnitChange="onChildEvent"
    :rules="rules"
    ref="textFieldUnit"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <v-menu
      v-model="showMenu"
      slot="append"
      transition="slide-y-transition"
      bottom
      :close-on-content-click="closeOnContentClick"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-if="settings.units.length > 1"
          v-bind="attrs"
          v-on="on"
          class="switch-unit-button"
          :disabled="lockUnit"
        >
          mdi-menu-down
        </v-icon>
      </template>
      <v-list>
        <v-list-item
          v-for="(unit, index) in settingNew.units"
          :key="index"
          @click="changeUnit(unit)"
        >
          <v-list-item-title>{{ unit }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-numeric-text-field>
</template>
<script>
import _ from 'lodash';
import UnitConverter from '@/plugins/unitconverter';
export default {
  name: 'NumericTextField',
  inheritAttrs: false,
  data() {
    return {
      lockUnit: null,
      settingNew: JSON.parse(JSON.stringify(this.settings)),
      rules: [],
      rule: {
        max: value => {
          var maxConverted = UnitConverter.convert(
            parseFloat(this.settings.max),
            this.settingNew.type,
            this.settingNew.baseUnit,
            this.settingNew.unit,
            null,
            this.rho,
          );
          var unit = this.settingNew.unit;
          return (
            value <= maxConverted ||
            `Input above maximum value of ${maxConverted} ${unit}`
          );
        },
        min: value => {
          var minConverted = UnitConverter.convert(
            parseFloat(this.settings.min),
            this.settingNew.type,
            this.settingNew.baseUnit,
            this.settingNew.unit,
            null,
            this.rho,
          );
          var unit = this.settingNew.unit;
          return (
            value >= minConverted ||
            `Input below minimum value of ${minConverted} ${unit}`
          );
        },
      },
      showMenu: false,
      closeOnContentClick: true,
    };
  },
  props: {
    value: null,
    settings: null,
    rho: null,
    integer: { type: Boolean, default: false },
  },
  methods: {
    limitRule(newValue) {
      var maximum;
      var minimum;

      if (String(this.rule.max).indexOf('<=') != -1) {
        //maximum
        if (this.settingNew.max != null) {
          if (this.settingNew.max == 0) {
            maximum = 0;
          } else {
            maximum = UnitConverter.convert(
              this.settingNew.max,
              this.settingNew.type,
              this.settingNew.baseUnit,
              this.settingNew.unit,
              null,
              this.rho,
            );
          }
        }
      } else if (String(this.rule.max).indexOf('<') != -1) {
        if (this.settingNew.max != null) {
          if (this.settingNew.max == 0) {
            maximum = 0;
          } else {
            maximum = UnitConverter.convert(
              this.settingNew.max,
              this.settingNew.type,
              this.settingNew.baseUnit,
              this.settingNew.unit,
              null,
              this.rho,
            );
          }
        }
      }

      if (String(this.rule.min).indexOf('>=') != -1) {
        //MIN
        if (this.settingNew.min != null) {
          if (this.settingNew.min == 0) {
            minimum = 0;
          } else {
            minimum = UnitConverter.convert(
              this.settingNew.min,
              this.settingNew.type,
              this.settingNew.baseUnit,
              this.settingNew.unit,
              null,
              this.rho,
            );
          }
        }
      } else if (String(this.rule.min).indexOf('>') != -1) {
        if (this.settingNew.min != null) {
          if (this.settingNew.min == 0) {
            minimum = 0;
          } else {
            minimum = UnitConverter.convert(
              this.settingNew.min,
              this.settingNew.type,
              this.settingNew.baseUnit,
              this.settingNew.unit,
              null,
              this.rho,
            );
          }
        }
      }
      //console.log("newValue " + newValue);
      //console.log("max " + maximum);
      //console.log("min " + minimum);
      if (newValue < minimum) {
        return minimum;
      }
      if (newValue > maximum) {
        return maximum;
      }
      return null;
    },
    onChildEvent(value) {
      this.lockUnit = value;
    },
    changeUnit(newUnit) {
      var index = this.settingNew.units.findIndex(
        unit => unit === newUnit,
      );
      this.settingNew.unit = newUnit;
      this.settingNew.precision = this.settingNew.precisions[index];
      this.$refs.textFieldUnit.$refs.textField.blur();
    },
  },
  computed: {
    input: {
      get: function() {
        if (isNaN(this.value)) {
          return '';
        }
        if (this.value == 0) {
          return '0';
        } else {
          return UnitConverter.convert(
            parseFloat(this.value),
            this.settingNew.type,
            this.settingNew.baseUnit,
            this.settingNew.unit,
            null,
            this.rho,
          );
        }
      },
      set: function(newValue) {
        var index = this.settingNew.units.findIndex(
          unit => unit === this.settingNew.baseUnit,
        );
        //if (this.limitRule(newValue) != null) {
        //  newValue = this.limitRule(newValue);
        //}
        this.$emit(
          'input',
          UnitConverter.convert(
            parseFloat(newValue),
            this.settingNew.type,
            this.settingNew.unit,
            this.settingNew.baseUnit,
            null, //this.settingNew.precisions[index],
            this.rho,
          ),
        );
        this.$emit('change');
      },
    },
    label() {
      return this.$attrs.label === undefined
        ? this.settings.label
        : this.$attrs.label;
    },
  },
  mounted() {
    var i;
    if (this.$attrs.rules != null) {
      for (i = 0; i < this.$attrs.rules.length; i++) {
        this.rules.push(this.$attrs.rules[i]);
      }
    }
    if (this.settingNew.min != null) {
      this.rules.push(this.rule.min);
    }
    if (this.settingNew.max != null) {
      this.rules.push(this.rule.max);
    }
  },
};
</script>
<style scoped>
.switch-unit-button {
  pointer-events: auto;
}
</style>
