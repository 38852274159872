import api from '@/plugins/api';
import { v4 as uuidv4 } from 'uuid';

const namespaced = true;

const state = {
  materials: JSON.parse(localStorage.getItem('materials')) || [],
  emptyMaterial: {
    publicId: -1,
    name: '',
    formula: '',
    cas: '',
    phase: 'g',
    molarMass: null,
    hf0: 0.0,
    matprops: [],
    elementalMassFractions: {},
    rho: null,
    rhoN: null,
    molarVolume: null,
    LHVBoie: null,
    LHVNist: null,
    ljEpsK: null,
    ljSigma: null,
    CHRatio: null,
    O2Demand: null,
  },
  emptyMatprop: {
    name: '',
    approxMethod: 'constant',
    yConst: 1.0,
    xLinear: [],
    yLinear: [],
    TMin: null,
    TMax: null,
    polynomials: [],
  },
  emptyPolynomial: {
    publicId: -1,
    TMin: 293.15,
    TMax: 393.15,
    formula: '',
    coefficients: [1],
    exponents: [0],
    functions: [null],
  },
  emptySpecies: {
    publicId: -1,
    name: '',
    formula: '',
    cas: '',
    phase: 'g',
    molarMass: 0.0,
    massFraction: 0.0,
    volumeFraction: 0.0,
    moleFraction: 0.0,
  },
  emptyElementalAnalysis: {
    C: 0.0,
    H: 0.0,
    O: 0.0,
    N: 0.0,
    S: 0.0,
    Cl: 0.0,
    F: 0.0,
    Br: 0.0,
    Si: 0.0,
    Na: 0.0,
    Mo: 0.0,
    P: 0.0,
    I: 0.0,
  },
  matpropDetails: [
    {
      name: 'c_p',
      fullName: 'Heat Capacity',
      symbol: 'c_p',
      unit: 'J/(kg K)',
      phases: ['g', 'l', 's'],
    },
    {
      name: 'h',
      fullName: 'Enthalpy',
      symbol: 'h',
      unit: 'J/kg',
      phases: ['g', 'l', 's'],
    },
    {
      name: 's',
      fullName: 'Entropy',
      symbol: 's',
      unit: 'J/(kg K)',
      phases: ['g', 'l', 's'],
    },
    {
      name: 'lam',
      fullName: 'Thermal Conductivity',
      symbol: '\\lambda',
      unit: 'W/(m K)',
      phases: ['g', 'l', 's'],
    },
    {
      name: 'p_sat',
      fullName: 'Saturation Pressure',
      symbol: 'p_{sat}',
      unit: 'Pa',
      phases: ['l'],
    },
  ],
};

const getters = {
  materials: state => state.materials,
  gases: state => state.materials.filter(mat => mat.phase === 'g'),
  liquids: state => state.materials.filter(mat => mat.phase === 'l'),
  solids: state => state.materials.filter(mat => mat.phase === 's'),
  emptyMaterial: state => () => {
    let newMaterial = JSON.parse(JSON.stringify(state.emptyMaterial));
    return newMaterial;
  }, // now a function!
  emptyMatprop: state => () => {
    let newMatprop = JSON.parse(JSON.stringify(state.emptyMatprop));
    newMatprop.TMin = 223.15;
    newMatprop.TMax = 2273.15;
    return newMatprop;
  }, // now a function!
  emptyPolynomial: state => () => {
    let newPolyonmial = JSON.parse(
      JSON.stringify(state.emptyPolynomial),
    );
    newPolyonmial.publicId = uuidv4();
    return newPolyonmial;
  }, // now a function!
  emptySpecies: state => () => {
    let newSpecies = JSON.parse(JSON.stringify(state.emptySpecies));
    return newSpecies;
  }, // now a function!
  emptyElementalAnalysis: state => () => {
    let newElementalAnalysis = JSON.parse(
      JSON.stringify(state.emptyElementalAnalysis),
    );
    return newElementalAnalysis;
  }, // now a function!
  matpropDetails: state => state.matpropDetails,
  htmlFormula: state => formula => {
    return formula
      .split(/(\d+)/)
      .map((s, i) => (i % 2 ? `<sub>${s}</sub>` : s))
      .join('');
  },
  stringFormula: state => formula => {
    var string = formula;
    var subscriptNumbers = string.match(/\d+/g);
    var subscriptNumber;
    if (subscriptNumbers != null) {
      for (var i = 0; i < subscriptNumbers.length; i++) {
        subscriptNumber = subscriptNumbers[i].split('');
        if (subscriptNumber != null) {
          for (var j = 0; j < subscriptNumber.length; j++) {
            switch (subscriptNumber[j]) {
              case '0':
                string = string.replace(subscriptNumber[j], '\u2080');
                break;
              case '1':
                string = string.replace(subscriptNumber[j], '\u2081');
                break;
              case '2':
                string = string.replace(subscriptNumber[j], '\u2082');
                break;
              case '3':
                string = string.replace(subscriptNumber[j], '\u2083');
                break;
              case '4':
                string = string.replace(subscriptNumber[j], '\u2084');
                break;
              case '5':
                string = string.replace(subscriptNumber[j], '\u2085');
                break;
              case '6':
                string = string.replace(subscriptNumber[j], '\u2086');
                break;
              case '7':
                string = string.replace(subscriptNumber[j], '\u2087');
                break;
              case '8':
                string = string.replace(subscriptNumber[j], '\u2088');
                break;
              case '9':
                string = string.replace(subscriptNumber[j], '\u2089');
                break;
              default:
                break;
            }
          }
        }
      }
    }
    return string;
  },
};

const actions = {
  getMaterials({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('materials/').then(
        response => {
          var materials = response.data.materials;
          commit('setMaterials', materials);
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  getMaterial({ commit }, publicId) {
    return new Promise((resolve, reject) => {
      api.get(`materials/${publicId}`).then(
        response => {
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  addMaterial({ commit }, material) {
    return new Promise((resolve, reject) => {
      api.post('materials/', material).then(
        response => {
          if ('materials' in response.data) {
            commit('addMaterials', response.data.materials);
          } else if ('material' in response.data) {
            commit('addMaterial', response.data.material);
          }
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  deleteMaterial({ commit }, publicId) {
    return new Promise((resolve, reject) => {
      if (confirm('Are you sure you want to delete this material?')) {
        api.delete(`materials/${publicId}`).then(
          response => {
            commit('deleteMaterial', publicId);

            resolve(response);
          },
          error => reject(error),
        );
      } else {
        reject('Material deletion aborted by user.');
      }
    });
  },
  destroyMaterials({ commit }) {
    localStorage.removeItem('materials');
    commit('setMaterials', []);
  },
  deleteAllMaterials({ commit }, publicId) {
    return new Promise((resolve, reject) => {
      if (
        confirm(
          'Are you sure you want to delete all (!!!) materials from database?',
        )
      ) {
        api.delete(`materials/`).then(
          response => {
            commit('removeAllMaterials');
            resolve(response);
          },
          error => reject(error),
        );
      } else {
        reject('Material deletion aborted by user.');
      }
    });
  },
  updateMaterial({ commit }, updatedMaterial) {
    return new Promise((resolve, reject) => {
      if (confirm('Are you sure you want to update this material?')) {
        api
          .put(
            `materials/${updatedMaterial.publicId}`,
            updatedMaterial,
          )
          .then(
            response => {
              commit('updateMaterial', response.data.material);
              resolve(response);
            },
            error => reject(error),
          );
      } else {
        reject('Updating Material aborted by user.');
      }
    });
  },
  scrapeChemkinFile({ get }, formData) {
    return new Promise((resolve, reject) => {
      api
        .post('materials/chemkinscraper', formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(
          response => {
            var materials = response.data.materials;
            for (var i in materials) {
              var material = materials[i];
              material.matprops = material.matprops.filter(
                matprop => matprop !== null,
              );
            }
            resolve(response);
          },
          error => reject(error),
        );
    });
  },
  scrapeNist({ get }, params) {
    return new Promise((resolve, reject) => {
      api
        .get('materials/nistscraper', {
          params: {
            searchPhrase: params.searchPhrase,
            searchBy: params.searchBy,
            phase: params.phase,
          },
        })
        .then(
          response => {
            var materials = response.data.materials;
            for (var i in materials) {
              var material = materials[i];
              material.matprops = material.matprops.filter(
                matprop => matprop !== null,
              );
            }
            resolve(response);
          },
          error => reject(error),
        );
    });
  },
  createMaterial(
    { getter },
    {
      material,
      calculateMolarMass,
      calculateMolarVolume,
      calculateDensity,
    },
  ) {
    return new Promise((resolve, reject) => {
      api
        .post('materials/create', {
          material,
          calculateMolarMass,
          calculateMolarVolume,
          calculateDensity,
        })
        .then(
          response => {
            resolve(response);
          },
          error => reject(error),
        );
    });
  },
  createMaterialFromElementalMassFractions(
    { getter },
    { elementalMassFractions, material, hMelt, hEvap },
  ) {
    return new Promise((resolve, reject) => {
      api
        .post('materials/createFromElementalMassFractions', {
          elementalMassFractions,
          material,
          hMelt,
          hEvap,
        })
        .then(
          response => {
            resolve(response);
          },
          error => reject(error),
        );
    });
  },
  getElementalMassFractions({ getter }, formula) {
    return new Promise((resolve, reject) => {
      api.get(`materials/elementalMassFractions/${formula}`).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
  estimateHf0WithBoieFormula(context, formula) {
    return new Promise((resolve, reject) => {
      api.get(`materials/hf0/${formula}`).then(
        response => {
          resolve(response);
        },
        error => reject(error),
      );
    });
  },
  getMolarMass({ get }, formula) {
    return new Promise((resolve, reject) => {
      api.get(`materials/molarMass/${formula}`).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
  getRhoN({ get }, molarMass) {
    return new Promise((resolve, reject) => {
      api
        .get('materials/density', {
          params: {
            molarMass: molarMass,
          },
        })
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          },
        );
    });
  },
  getLHVBoie({ get }, formula) {
    return new Promise((resolve, reject) => {
      api.get(`materials/LHVBoie/${formula}`).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
  getLHVNist({ get }, params) {
    return new Promise((resolve, reject) => {
      api
        .get('materials/LHVNist', {
          params: {
            formula: params.formula,
            molarMass: params.molarMass,
            hf0: params.hf0,
          },
        })
        .then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          },
        );
    });
  },
  getCHRatio({ get }, formula) {
    return new Promise((resolve, reject) => {
      api.get(`materials/CHRatio/${formula}`).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
  getO2Demand({ get }, formula) {
    return new Promise((resolve, reject) => {
      api.get(`materials/O2Demand/${formula}`).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
};

const mutations = {
  setMaterials: (state, materials) => {
    state.materials = materials;
    try {
      localStorage.setItem(
        'materials',
        JSON.stringify(state.materials),
      );
    } catch (error) {
      console.log(error);
    }
  },
  addMaterial: (state, material) => {
    state.materials.push(material);
    try {
      localStorage.setItem(
        'materials',
        JSON.stringify(state.materials),
      );
    } catch (error) {
      console.log(error);
    }
  },
  addMaterials: (state, materials) => {
    state.materials.push(...materials);
    try {
      localStorage.setItem(
        'materials',
        JSON.stringify(state.materials),
      );
    } catch (error) {
      console.log(error);
    }
  },
  updateMaterial: (state, updatedMaterial) => {
    const index = state.materials.findIndex(
      material => material.publicId === updatedMaterial.publicId,
    );
    if (index != -1) {
      state.materials.splice(index, 1, updatedMaterial);
      try {
        localStorage.setItem(
          'materials',
          JSON.stringify(state.materials),
        );
      } catch (error) {
        console.log(error);
      }
    }
  },
  deleteMaterial: (state, publicId) => {
    const index = state.materials.findIndex(
      material => material.publicId === publicId,
    );
    if (index != -1) {
      state.materials.splice(index, 1);
      try {
        localStorage.setItem(
          'materials',
          JSON.stringify(state.materials),
        );
      } catch (error) {
        console.log(error);
      }
    }
  },
  removeAllMaterials: state => {
    state.materials = [];
    try {
      localStorage.setItem(
        'materials',
        JSON.stringify(state.materials),
      );
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
