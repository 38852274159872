import _ from 'lodash';

class UnitConverter {
  static types = {
    LENGTH: 'length',
    AREA: 'area',
    VOLUME: 'volume',
    FLOWRATE: 'flowRate',
    TEMPERATURE: 'temperature',
    PRESSURE: 'pressure',
    MOLARMASS: 'molarMass',
    MOLARVOLUME: 'molarVolume',
    DENSITY: 'density',
    ENTHALPY: 'enthalpy',
    POWER: 'power',
    O2DEMAND: 'O2Demand',
    POLLUTANT: 'Pollutant',
    UNITLESS: 'unitless',
  };
  static convert(
    value,
    type,
    fromUnit,
    toUnit,
    precision = null,
    rho = null,
    rho2 = null,
  ) {
    switch (type) {
      case this.types.LENGTH:
        value = this.convertLength(value, fromUnit, toUnit);
        break;
      case this.types.AREA:
        value = this.convertArea(value, fromUnit, toUnit);
        break;
      case this.types.VOLUME:
        value = this.convertArea(value, fromUnit, toUnit);
        break;
      case this.types.FLOWRATE:
        value = this.convertFlowRate(value, fromUnit, toUnit, rho);
        break;
      case this.types.TEMPERATURE:
        value = this.convertTemperature(value, fromUnit, toUnit);
        break;
      case this.types.PRESSURE:
        value = this.convertPressure(value, fromUnit, toUnit);
        break;
      case this.types.MOLARMASS:
        value = this.convertMolarMass(value, fromUnit, toUnit);
        break;
      case this.types.MOLARVOLUME:
        value = this.convertMolarVolume(value, fromUnit, toUnit);
        break;
      case this.types.DENSITY:
        value = this.convertDensity(value, fromUnit, toUnit);
        break;
      case this.types.ENTHALPY:
        value = this.convertEnthalpy(value, fromUnit, toUnit, rho);
        break;
      case this.types.POWER:
        value = this.convertPower(value, fromUnit, toUnit);
        break;
      case this.types.O2DEMAND:
        value = this.convertO2Demand(
          value,
          fromUnit,
          toUnit,
          rho,
          rho2,
        );
        break;
      case this.types.POLLUTANT:
        value = this.convertPollutant(value, fromUnit, toUnit, rho);
        break;
      case this.types.UNITLESS:
        value = this.convertUnitless(value, fromUnit, toUnit);
        break;
      default:
        break;
    }
    return precision === null ? value : _.round(value, precision);
  }
  static convertLength(value, fromUnit, toUnit) {
    value = this.convertLengthToSI(value, fromUnit);
    return this.convertLengthFromSI(value, toUnit);
  }
  static convertLengthFromSI(value, unit) {
    switch (unit) {
      case 'dm':
        return value * 1e1;
      case 'cm':
        return value * 1e2;
      case 'mm':
        return value * 1e3;
      case 'Å':
        return value * 1e10;
      case 'km':
        return value * 1e-3;
      default:
        return value;
    }
  }
  static convertLengthToSI(value, unit) {
    switch (unit) {
      case 'dm':
        return value * 1e-1;
      case 'cm':
        return value * 1e-2;
      case 'mm':
        return value * 1e-3;
      case 'Å':
        return value * 1e-10;
      case 'km':
        return value * 1e3;
      default:
        return value;
    }
  }
  static convertArea(value, fromUnit, toUnit) {
    value = this.convertAreaToSI(value, fromUnit);
    return this.convertAreaFromSI(value, toUnit);
  }
  static convertAreaFromSI(value, unit) {
    switch (unit) {
      case 'dm²':
        return value * 1e2;
      case 'cm²':
        return value * 1e4;
      case 'mm²':
        return value * 1e6;
      case 'km²':
        return value * 1e-6;
      default:
        return value;
    }
  }
  static convertAreaToSI(value, unit) {
    switch (unit) {
      case 'dm²':
        return value * 1e-2;
      case 'cm²':
        return value * 1e-4;
      case 'mm²':
        return value * 1e-6;
      case 'km²':
        return value * 1e6;
      default:
        return value;
    }
  }
  static convertVolume(value, fromUnit, toUnit) {
    value = this.convertVolumeToSI(value, fromUnit);
    return this.convertVolumeFromSI(value, toUnit);
  }
  static convertVolumeFromSI(value, unit) {
    switch (unit) {
      case 'dm³':
        return value * 1e3;
      case 'cm³':
        return value * 1e6;
      case 'mm³':
        return value * 1e9;
      case 'km³':
        return value * 1e-9;
      default:
        return value;
    }
  }
  static convertVolumeToSI(value, unit) {
    switch (unit) {
      case 'dm³':
        return value * 1e-3;
      case 'cm³':
        return value * 1e-6;
      case 'mm³':
        return value * 1e-9;
      case 'km³':
        return value * 1e9;
      default:
        return value;
    }
  }
  static convertFlowRate(value, fromUnit, toUnit, rho) {
    value = this.convertFlowRateToSI(value, fromUnit, rho);
    return this.convertFlowRateFromSI(value, toUnit, rho);
  }
  static convertFlowRateFromSI(value, unit, rho) {
    switch (unit) {
      case 'kg/h':
        return value * 3600;
      case 'Nm³/s':
      case 'm³/s':
        return value / rho;
      case 'Nm³/h':
      case 'm³/h':
        return (value / rho) * 3600;
      default:
        return value;
    }
  }
  static convertFlowRateToSI(value, unit, rho) {
    switch (unit) {
      case 'kg/h':
        return value / 3600;
      case 'Nm³/s':
      case 'm³/s':
        return value * rho;
      case 'Nm³/h':
      case 'm³/h':
        return (value * rho) / 3600;
      default:
        return value;
    }
  }
  static convertTemperature(value, fromUnit, toUnit) {
    value = this.convertTemperatureToSI(value, fromUnit);
    return this.convertTemperatureFromSI(value, toUnit);
  }
  static convertTemperatureFromSI(value, unit) {
    switch (unit) {
      case '°C':
        return value - 273.15;
      case '°F':
        return (value - 273.15) * 1.8 + 32;
      default:
        return value;
    }
  }
  static convertTemperatureToSI(value, unit) {
    switch (unit) {
      case '°C':
        return value + 273.15;
      case '°F':
        return (value - 32) / 1.8 + 273.15;
      default:
        return value;
    }
  }
  static convertPressure(value, fromUnit, toUnit) {
    value = this.convertPressureToSI(value, fromUnit);
    return this.convertPressureFromSI(value, toUnit);
  }
  static convertPressureFromSI(value, unit) {
    switch (unit) {
      case 'kPa':
        return value * 1e-3;
      case 'MPa':
        return value * 1e-6;
      case 'bar':
        return value * 1e-5;
      case 'mbar':
        return value * 1e-2;
      case 'atm':
        return value / 101325;
      default:
        return value;
    }
  }
  static convertPressureToSI(value, unit) {
    switch (unit) {
      case 'kPa':
        return value * 1e3;
      case 'MPa':
        return value * 1e6;
      case 'bar':
        return value * 1e5;
      case 'mbar':
        return value * 1e2;
      case 'atm':
        return value * 101325;
      default:
        return value;
    }
  }
  static convertMolarMass(value, fromUnit, toUnit) {
    value = this.convertMolarMassToSI(value, fromUnit);
    return this.convertMolarMassFromSI(value, toUnit);
  }
  static convertMolarMassFromSI(value, unit) {
    switch (unit) {
      case 'kg/kmol':
        return value * 1e3;
      case 'g/kmol':
        return value * 1e6;
      default:
        return value;
    }
  }
  static convertMolarMassToSI(value, unit) {
    switch (unit) {
      case 'kg/kmol':
        return value * 1e-3;
      case 'g/kmol':
        return value * 1e-6;
      default:
        return value;
    }
  }
  static convertMolarVolume(value, fromUnit, toUnit) {
    value = this.convertMolarVolumeToSI(value, fromUnit);
    return this.convertMolarVolumeFromSI(value, toUnit);
  }
  static convertMolarVolumeFromSI(value, unit) {
    switch (unit) {
      case 'Nm³/kmol':
      case 'm³/kmol':
        return value * 1e3;
      default:
        return value;
    }
  }
  static convertMolarVolumeToSI(value, unit) {
    switch (unit) {
      case 'Nm³/kmol':
      case 'm³/kmol':
        return value * 1e-3;
      default:
        return value;
    }
  }
  static convertDensity(value, fromUnit, toUnit) {
    value = this.convertDensityToSI(value, fromUnit);
    return this.convertDensityFromSI(value, toUnit);
  }
  static convertDensityFromSI(value, unit) {
    switch (unit) {
      case 'g/cm³':
      case 'g/Ncm³':
        return value * 1e-3;
      default:
        return value;
    }
  }
  static convertDensityToSI(value, unit) {
    switch (unit) {
      case 'g/cm³':
      case 'g/Ncm³':
        return value * 1e3;
      default:
        return value;
    }
  }
  static convertPower(value, fromUnit, toUnit) {
    value = this.convertPowerToSI(value, fromUnit);
    return this.convertPowerFromSI(value, toUnit);
  }
  static convertPowerFromSI(value, unit) {
    switch (unit) {
      case 'kW':
        return value * 1e-3;
      case 'MW':
        return value * 1e-6;
      default:
        return value;
    }
  }
  static convertPowerToSI(value, unit) {
    switch (unit) {
      case 'kW':
        return value * 1e3;
      case 'MW':
        return value * 1e6;
      default:
        return value;
    }
  }
  static convertEnthalpy(value, fromUnit, toUnit, rho) {
    value = this.convertEnthalpyToSI(value, fromUnit, rho);
    return this.convertEnthalpyFromSI(value, toUnit, rho);
  }
  static convertEnthalpyFromSI(value, unit, rho) {
    switch (unit) {
      case 'kJ/kg':
      case 'kJ/mol':
        return value * 1e-3;
      case 'MJ/kg':
      case 'MJ/mol':
        return value * 1e-6;
      case 'kWh/kg':
        return (value / 3600) * 1e-3;
      case 'kcal/kg':
        return value * 0.239 * 1e-3;
      case 'kJ/Nm³':
      case 'kJ/m³':
        return value * rho * 1e-3;
      case 'MJ/Nm³':
      case 'MJ/m³':
        return value * rho * 1e-6;
      case 'kWh/Nm³':
      case 'kWh/m³':
        return ((value * rho) / 3600) * 1e-3;
      case 'kcal/Nm³':
      case 'kcal/m³':
        return value * rho * 0.239 * 1e-3;
      default:
        return value;
    }
  }
  static convertEnthalpyToSI(value, unit, rho) {
    switch (unit) {
      case 'kJ/kg':
      case 'kJ/mol':
        return value * 1e3;
      case 'MJ/kg':
      case 'MJ/mol':
        return value * 1e6;
      case 'kWh/kg':
        return value * 3600 * 1e3;
      case 'MWh/kg':
        return value * 3600 * 1e6;
      case 'kcal/kg':
        return (value / 0.239) * 1e3;
      case 'kJ/Nm³':
      case 'kJ/m³':
        return (value / rho) * 1e3;
      case 'MJ/Nm³':
      case 'MJ/m³':
        return (value / rho) * 1e6;
      case 'kWh/Nm³':
      case 'kWh/m³':
        return (value / rho) * 3600 * 1e3;
      case 'kcal/Nm³':
      case 'kcal/m³':
        return (value / rho / 0.239) * 1e3;
      default:
        return value;
    }
  }
  static convertO2Demand(value, fromUnit, toUnit, rhoO2, rhoFuel) {
    value = this.convertO2DemandToSI(value, fromUnit, rhoO2, rhoFuel);
    return this.convertO2DemandFromSI(value, toUnit, rhoO2, rhoFuel);
  }
  static convertO2DemandFromSI(value, unit, rhoO2, rhoFuel) {
    var numeratorUnit = unit.split('/')[0];
    var denominatorUnit = unit.split('/')[1];
    if (numeratorUnit.includes('Nm³')) {
      value /= rhoO2;
    }
    if (denominatorUnit.includes('Nm³')) {
      value *= rhoFuel;
    }
    return value;
  }
  static convertO2DemandToSI(value, unit, rhoO2, rhoFuel) {
    var numeratorUnit = unit.split('/')[0];
    var denominatorUnit = unit.split('/')[1];
    if (numeratorUnit.includes('Nm³')) {
      value *= rhoO2;
    }
    if (denominatorUnit.includes('Nm³')) {
      value /= rhoFuel;
    }
    return value;
  }
  static convertPollutant(value, fromUnit, toUnit, rhoPollutant) {
    value = this.convertPollutantToSI(value, fromUnit, rhoPollutant);
    return this.convertPollutantFromSI(value, toUnit, rhoPollutant);
  }
  static convertPollutantFromSI(value, unit, rhoPollutant) {
    switch (unit) {
      case '%vol':
        return value * 1e2;
      case 'ppm':
        return value * 1e6;
      case 'mg/Nm³':
        return value * 1e6 * rhoPollutant;
      default:
        return value;
    }
  }
  static convertPollutantToSI(value, unit, rhoPollutant) {
    switch (unit) {
      case '%vol':
        return value * 1e-2;
      case 'ppm':
        return value * 1e-6;
      case 'mg/Nm³':
        return value / 1e-6 / rhoPollutant;
      default:
        return value;
    }
  }
  static convertUnitless(value, fromUnit, toUnit) {
    value = this.convertUnitlessToBase(value, fromUnit);
    return this.convertUnitlessFromBase(value, toUnit);
  }
  static convertUnitlessFromBase(value, unit) {
    switch (unit) {
      case '%':
      case '%vol':
      case '%mass':
      case '%mol':
        return value * 1e2;
      default:
        return value;
    }
  }
  static convertUnitlessToBase(value, unit) {
    switch (unit) {
      case '%':
      case '%vol':
      case '%mass':
      case '%mol':
        return value * 1e-2;
      default:
        return value;
    }
  }
}

export default UnitConverter;
