import FileSaver from 'file-saver';
import _ from 'lodash';
import router from '@/router';
import Vuetify from '@/plugins/vuetify';
import colors from 'vuetify/lib/util/colors';
import solverReadme from '@/assets/Solver.md';

const namespaced = true;

const state = {
  version: process.env.VUE_APP_VERSION,
  snack: {
    text: '',
    color: 'green',
    timeout: 5000,
  },
  loading: { status: false, text: '' },
  defaultThemeName: process.env.VUE_APP_DEFAULT_THEME_NAME || 'light',
  themes: {
    light: {
      primary: colors.blue.darken2,
      secondary: colors.grey.darken3,
      tertiary: colors.shades.white,
      accent: colors.blue.accent1,
      error: colors.red.accent2,
      info: colors.blue.base,
      success: colors.green.base,
      warning: colors.orange.darken1,
      background: colors.shades.white,
      titlebar: colors.shades.white,
      leftDrawer: colors.shades.white,
      rightDrawer: colors.shades.white,
    },
    dark: {
      primary: colors.blue.darken2,
      secondary: colors.grey.darken3,
      tertiary: colors.shades.white,
      accent: colors.blue.accent1,
      error: colors.red.accent2,
      info: colors.blue.base,
      success: colors.green.base,
      warning: colors.orange.darken1,
      background: '#121212',
      titlebar: colors.grey.darken4,
      leftDrawer: '#363636',
      rightDrawer: '#363636',
    },
    enrag: {
      primary: colors.blue.darken2,
      secondary: colors.grey.darken3,
      tertiary: colors.shades.white,
      accent: colors.blue.accent1,
      error: colors.red.accent2,
      info: colors.blue.base,
      success: '#99D420', //colors.green.base,
      warning: colors.orange.darken1,
      background: colors.grey.lighten4,
      titlebar: '#f26f43', // colors.deepOrange, // '#EC6726'
      leftDrawer: colors.lightGreen.lighten3, // '#AECA06', //
      rightDrawer: colors.blueGrey.lighten5, // '#6F6F6F', //
    },
    cs: {
      primary: colors.blue.darken2,
      secondary: colors.grey.darken3,
      tertiary: colors.shades.white,
      accent: colors.blue.accent1,
      error: colors.red.accent2,
      info: colors.blue.base,
      success: colors.green.base,
      warning: colors.orange.darken1,
      background: colors.grey.lighten4,
      titlebar: '#800000', // colors.deepOrange, // '#EC6726'
      leftDrawer: '#FFC000', // '#AECA06', //
      rightDrawer: '#FFC000', // '#6F6F6F', //
    },
  },
  logo: process.env.VUE_APP_LOGO || 'logo_cs.jpg',
  favicon: process.env.VUE_APP_FAVICON || 'favicon_cs.ico',
  readme: solverReadme,
};

const getters = {
  version: state => state.version,
  snack: state => state.snack,
  loading: state => state.loading,
  defaultThemeName: state => state.defaultThemeName,
  themes: state => state.themes,
  logo: state => state.logo,
  favicon: state => state.favicon,
  readme: state => state.readme,
};

const actions = {
  setSnack({ commit }, snack) {
    commit('setSnack', snack);
  },
  setLoading({ commit }, loading) {
    if (typeof loading === 'boolean') {
      loading = { status: loading, text: '' };
    }
    commit('setLoading', loading);
  },
  saveObjectToFile(context, { obj, filename }) {
    var stringified_obj = JSON.stringify(obj, null, 2);
    var blob = new Blob([stringified_obj], {
      type: 'application/json',
    });
    FileSaver.saveAs(blob, `${filename}.json`);
  },
  loadObjectFromFile(context, file) {
    const fr = new FileReader();
    return new Promise((resolve, reject) => {
      fr.onload = () => {
        try {
          resolve({
            data: {
              msg: 'File successfully loaded',
              project: JSON.parse(fr.result),
            },
          });
        } catch (e) {
          reject({
            response: {
              data: {
                msg: 'Error while loading file',
              },
            },
          });
        }
      };
      fr.readAsText(file);
    });
  },
  initApp({ getters, dispatch, rootGetters }, route) {
    return new Promise((resolve, reject) => {
      const favicon = document.getElementById('favicon');
      favicon.href = getters.favicon;
      if (rootGetters.loggedIn) {
        // load user data
        dispatch('loadUser')
          .then(response => {
            dispatch(
              'changeTheme',
              response.data.user.settings.theme,
            );
            // load materials
            dispatch('loadMaterials')
              .then(response => {
                console.log('materials loaded successfully');
                dispatch('setSnack', {
                  text: response.data.msg,
                  color: 'success',
                });
                dispatch('loadCurrentProject')
                  .then(response => resolve(response))
                  .catch(error => reject(error));
              })
              .catch(error => {
                console.log('Error loading materials');
                dispatch('setSnack', {
                  text: error.response.data.msg,
                  color: 'error',
                });
                reject(error);
              });
            dispatch(
              'mixtures/getDatabaseMixtures',
              {},
              { root: true },
            ).catch(error => reject(error));
          })
          .catch(error => {
            console.log('Error fetching user data from database...');
            dispatch('logoutUser', { force: true }, { root: true })
              .catch(error => {
                console.log('Error during logout');
                console.log(error);
              })
              .finally(() => {
                reject(error);
                switch (route.name) {
                  case 'Reset Password':
                    break;
                  case 'Login':
                    break;
                  default:
                    router.push({ name: 'Login' });
                }
              });
          });
      } else {
        switch (route.name) {
          case 'Confirm Email':
          case 'Activate User':
          case 'Reset Password':
          case 'Login':
          case null:
            break;
          default:
            router.push({ name: 'Login' });
        }
      }
    });
  },
  loadUser({ commit, dispatch, rootGetters }) {
    dispatch('setLoading', {
      status: true,
      text: 'Fetch user data',
    });
    return new Promise((resolve, reject) => {
      dispatch('getUser', {}, { root: true })
        .then(response => {
          commit('setCurrentUser', response.data.user, {
            root: true,
          });
          console.log('User fetched from database');
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  loadMaterials({ dispatch, rootGetters }) {
    dispatch('setLoading', {
      status: true,
      text: 'Loading Materials',
    });
    return new Promise((resolve, reject) => {
      var materials = rootGetters['materials/materials'];
      if (materials.length === 0) {
        dispatch('materials/getMaterials', {}, { root: true })
          .then(response => {
            response.data.msg = 'Materials fetched from database';
            resolve(response);
          })
          .catch(error => {
            error.response = {
              data: {
                msg: 'Failed to fetch materials from database',
              },
            };
            reject(error);
          });
      } else {
        resolve({
          data: { msg: 'Materials loaded from local storage' },
        });
      }
    });
  },
  loadCurrentProject({ dispatch, rootGetters }) {
    dispatch('setLoading', {
      status: true,
      text: 'Loading User Project',
    });
    return new Promise((resolve, reject) => {
      var currentProject = rootGetters['projects/currentProject'];
      dispatch('projects/setCurrentProject', currentProject, {
        root: true,
      })
        .then(response => resolve(response))
        .catch(error => {
          console.log(error);
          error.response.data.msg += ' Empty Project loaded.';
          dispatch('projects/initProject', {}, { root: true });
          reject(error);
        });
    });
  },
  changeTheme({ commit, getters }, themeName) {
    let defaultThemeName = getters.defaultThemeName;
    console.log(defaultThemeName);
    var theme =
      getters.themes[themeName] || getters.themes[defaultThemeName];
    switch (themeName) {
      case 'dark':
        Vuetify.framework.theme.dark = true;
        Vuetify.framework.theme.themes.dark = theme;
        break;
      default:
        Vuetify.framework.theme.dark = false;
        Vuetify.framework.theme.themes.light = theme;
    }
  },
};

const mutations = {
  setSnack: (state, snack) => (state.snack = snack),
  setLoading: (state, loading) => (state.loading = loading),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
