import Vuex from 'vuex';
import Vue from 'vue';
import materials from './modules/materials';
import projects from './modules/projects';
import mixtures from './modules/mixtures';
import combustionChambers from './modules/combustionchambers';
import auth from './modules/auth';
import misc from './modules/misc';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    materials,
    projects,
    mixtures,
    combustionChambers,
    auth,
    misc,
  },
});
