<template>
  <v-footer app>
    <span>&copy; {{ new Date().getFullYear() }}</span>
    <v-spacer></v-spacer>
    <span> v{{ version }}</span>
    <v-spacer></v-spacer>
    <v-btn depressed text to="/about"> About </v-btn>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters('misc', ['version']),
  },
};
</script>

<style></style>
