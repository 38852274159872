import axios from 'axios';

// var baseURL = process.env.VUE_APP_API_URL;
var protocol = window.location.protocol;
var hostname = window.location.hostname;
var port = process.env.VUE_APP_API_PORT;

port = port ? `:${port}` : '';
var baseURL = `${protocol}//${hostname}${port}/api/`;
var api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  config => {
    config.headers.Authorization =
      'Bearer ' + localStorage.getItem('accessToken');
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default api;
