<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title> Load Project</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon class="info" @click="loadProject" v-on="on">
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> Load </span>
          </v-tooltip>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-form class="px-3" ref="form" :disabled="!loading">
              <v-container fluid>
                <v-row style="mx-4">
                  <v-col cols="12">
                    <v-file-input
                      show-size
                      label="Select Project File"
                      v-model="chosenFile"
                      :rules="requiredRule"
                      accept=".json"
                    />
                  </v-col>
                  <v-col> </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoadProjectPopup',
  components: {},
  data() {
    return {
      dialog: false,
      requiredRule: [v => (v && !!v) || 'File is required'],
      chosenFile: null,
      project: null,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    reset() {
      this.$refs.form.reset();
      this.project = null;
    },
    loadProject() {
      if (this.$refs.form.validate()) {
        if (
          confirm(
            'Are you sure to load another project? Current project data might be lost if not saved. Continue?',
          )
        ) {
          this.$store.dispatch('misc/setLoading', true);
          this.$store
            .dispatch('misc/loadObjectFromFile', this.chosenFile)
            .then(response => {
              this.$store
                .dispatch(
                  'projects/setCurrentProject',
                  response.data.project,
                )
                .then(response => {
                  this.$store.dispatch('misc/setSnack', {
                    text: response.data.msg,
                    color: 'success',
                  });
                })
                .catch(error => {
                  this.$store.dispatch('misc/setSnack', {
                    text: error.response.data.msg,
                    color: 'error',
                  });
                });
              this.close();
            })
            .catch(error => {
              this.$store.dispatch('misc/setSnack', {
                text: error.response.data.msg,
                color: 'error',
              });
            })
            .finally(() =>
              this.$store.dispatch('misc/setLoading', false),
            );
        }
      } else {
        this.$store.dispatch('misc/setLoading', false);
        var snack = {
          text: 'Please select a file first',
          color: 'error',
        };
        this.$store.dispatch('misc/setSnack', snack);
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    ...mapGetters('misc', ['loading']),
    pretty() {
      return JSON.stringify(this.project, null, 4);
    },
  },
};
</script>

<style></style>
