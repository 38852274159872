<template>
  <v-text-field
    v-bind="$attrs"
    v-model="input"
    @focus="focus"
    @blur="blur"
    @keydown="keydownEvent($event)"
    ref="textField"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </v-text-field>
</template>
<script>
import _ from 'lodash';
import UnitConverter from '@/plugins/unitconverter';
export default {
  name: 'NumericTextField',
  inheritAttrs: false,
  data() {
    return {
      dataChange: false,
      data: this.value,
      input: null,
    };
  },
  props: {
    value: null,
    precision: null,
    integer: { type: Boolean, default: false },
  },
  methods: {
    focus() {
      if (
        isNaN(this.value) ||
        this.value === '' ||
        this.value === null
      ) {
        this.input = '';
      } else if (this.value === 0) {
        this.input = '0';
      } else {
        this.input = this.value;
        //this.input = UnitConverter.convert(
        //  this.value,
        //  null,
        //  null,
        //  null,
        //  null,
        //  null,
        //);
      }
    },
    blur() {
      this.checkSciNot();
      if (
        isNaN(this.input) ||
        this.input === '' ||
        this.input === null
      ) {
        this.input = '';
      }

      if (this.dataChange == true) {
        //only save input after KeydownEvent
        //this.data = this.round(this.input);
        this.data = this.input;
        this.$emit('lockUnitChange', false);
        this.dataChange = false;
      }
      if (this.input != 0) {
        this.input = this.round(this.input);
        this.input = this.numInSciNot(this.input);
      }
    },
    checkSciNot() {
      var e = String(this.input).split('e');
      var E = String(this.input).split('E');
      if (e[1] == '') {
        this.input = e[0];
      } else if (E[1] == '') {
        this.input = E[0];
      }
    },
    round(val) {
      if (this.precision != null) {
        return UnitConverter.convert(
          parseFloat(val),
          null,
          null,
          null,
          this.precision,
          null,
        );
      } else {
        return val;
      }
    },
    numInSciNot(val) {
      const numInSciNot = {};
      [numInSciNot.coefficient, numInSciNot.exponent] = Number(val)
        .toExponential()
        .split('e')
        .map(item => Number(item));
      var coeffAsString;

      if (
        numInSciNot.coefficient == null ||
        numInSciNot.exponent == null
      ) {
        if (
          String(val).slice(val.length - 1, val.length) == 'e' ||
          String(val).slice(val.length - 1, val.length) == 'E'
        ) {
          coeffAsString = String(val).slice(0, -1);
        } else {
          coeffAsString = val;
        }
      } else if (
        numInSciNot.exponent > 5 ||
        numInSciNot.exponent < -3
      ) {
        coeffAsString = `${numInSciNot.coefficient}e${numInSciNot.exponent}`;
      } else {
        if (
          String(val).indexOf('e') != -1 ||
          String(val).indexOf('E') != -1
        ) {
          coeffAsString = parseFloat(
            numInSciNot.coefficient *
              Math.pow(10, numInSciNot.exponent),
          );
        } else {
          coeffAsString = val;
        }
      }
      return coeffAsString;
    },
    keydownEvent(e) {
      this.dataChange = true;
      this.$emit('lockUnitChange', true);
      let curserPos = this.$refs.textField.$refs.input.selectionStart;
      var allowedKeys = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Tab',
        'e',
        'E',
        '-',
      ];
      if (!this.integer) {
        allowedKeys.push(...[',', '.']);
      }
      const allowedNumbers = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
      ];

      if (e.ctrlKey) return;
      if (allowedNumbers.includes(e.key)) {
        //console.log("allowedNumbers " + e.key);
      } else if (allowedKeys.includes(e.key)) {
        //console.log("allowedKeys " + e.key);
        switch (e.key) {
          case 'ArrowRight':
          case 'ArrowLeft':
          case 'Delete':
          case 'Backspace':
            break;
          case ',':
            if (String(this.input).indexOf('.') != -1) {
              e.preventDefault();
            } else if (String(this.input).indexOf('.') == -1) {
              this.input =
                String(this.input).slice(0, curserPos) +
                '.' +
                String(this.input).slice(
                  curserPos,
                  this.input.length,
                );
              e.preventDefault();
            }
            break;
          case '.':
            if (String(this.input).indexOf('.') != -1) {
              e.preventDefault();
            }
            break;
          case '-':
            var ePos = String(this.input).indexOf('e');
            var EPos = String(this.input).indexOf('E');

            var divider = -1;
            if (ePos > EPos) {
              divider = ePos;
            } else {
              divider = EPos;
            }

            var partOne = String(this.input).slice(0, divider);
            var partTwo = String(this.input).slice(
              divider + 1,
              this.input.length,
            );
            if (String(this.input).indexOf('-') == -1) {
              // no '-'
              if (this.input.length == 0 || curserPos == 0) {
                this.input = e.key + this.input;
              } else if (
                String(this.input).slice(curserPos - 1, curserPos) ==
                  'e' ||
                String(this.input).slice(curserPos - 1, curserPos) ==
                  'E'
              ) {
                this.input =
                  String(this.input).slice(0, curserPos) +
                  e.key +
                  String(this.input).slice(
                    curserPos,
                    this.input.length,
                  );
              }
              //  e.preventDefault();
              //} else if (String(partOne).indexOf("-") != -1 && String(partTwo).indexOf("-") != -1) {  //part 1 and part 2 have '-'
              //  e.preventDefault();
            } else {
              if (String(partTwo).indexOf('-') == -1) {
                //part 2 have no'-'
                if (
                  String(this.input).slice(
                    curserPos - 1,
                    curserPos,
                  ) == 'e' ||
                  String(this.input).slice(
                    curserPos - 1,
                    curserPos,
                  ) == 'E'
                ) {
                  this.input =
                    String(this.input).slice(0, curserPos) +
                    e.key +
                    String(this.input).slice(
                      curserPos,
                      this.input.length,
                    );
                }
              }
              if (String(partOne).indexOf('-') == -1) {
                //part 1 have no'-'
                if (curserPos == 0) {
                  this.input = e.key + this.input;
                }
              }
              //e.preventDefault();
            }
            e.preventDefault();
            break;
          case 'e':
          case 'E':
            if (
              String(this.input).indexOf('e') != -1 ||
              String(this.input).indexOf('E') != -1
            ) {
              e.preventDefault();
            } else if (
              String(this.input).indexOf('e') == -1 &&
              String(this.input).indexOf('E') == -1
            ) {
              this.input += e.key;
              e.preventDefault();
            }
            break;
          default:
            break;
        }
      } else {
        e.preventDefault();
      }
    },
  },
  watch: {
    data() {
      this.$emit('input', this.data);
      this.$emit('change');
    },
    value() {
      this.input = this.value;
      if (
        isNaN(this.input) ||
        this.input === '' ||
        this.input === null
      ) {
        this.input = '';
      } else if (this.input === 0) {
        this.input = '0';
      } else {
        this.input = parseFloat(this.value);
        this.input = this.round(this.input);
        this.input = this.numInSciNot(this.input);
      }
    },
  },
  mounted() {
    this.input = this.value;
    if (
      isNaN(this.input) ||
      this.input === '' ||
      this.input === null
    ) {
      this.input = '';
    } else if (this.input === 0) {
      this.input = '0';
    } else {
      this.input = this.round(this.input);
      this.input = this.numInSciNot(this.input);
    }
  },
};
</script>
