<template>
  <nav>
    <v-app-bar app clipped-left clipped-right class="titlebar">
      <v-app-bar-nav-icon @click="drawerLeft = !drawerLeft">
      </v-app-bar-nav-icon>
      <img
        class="ml-2"
        :src="require(`@/assets/${logo}`)"
        :height="50"
      />
      <v-spacer></v-spacer>
      <v-toolbar-title>
        {{ currentPage }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="loggedIn && currentUser !== null">
        {{ currentUser.firstName }}
        {{ currentUser.lastName }}
      </v-toolbar-title>
      <v-app-bar-nav-icon @click="drawerRight = !drawerRight">
      </v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawerLeft"
      app
      left
      clipped
      v-if="loggedIn"
      class="leftDrawer"
    >
      <v-list dense>
        <template v-for="(drawerItem, i) in drawerLeftItems">
          <v-list-item
            v-if="drawerItem.title && showDrawer(drawerItem.role)"
            :key="i"
            link
            :to="drawerItem.route"
          >
            <v-list-item-action>
              <v-icon> {{ drawerItem.icon }} </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ drawerItem.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-else-if="
              drawerItem.divider && showDrawer(drawerItem.role)
            "
            :key="i"
            class="my-4 mx-2"
          />
          <v-spacer v-else-if="drawerItem.spacer" :key="i">
          </v-spacer>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="drawerRight"
      app
      clipped
      right
      class="rightDrawer"
    >
      <v-list dense>
        <template v-for="(drawerItem, i) in drawerRightItems">
          <v-list-group
            v-if="drawerItem.title && showDrawer(drawerItem.role)"
            :key="i"
            v-model="drawerItem.active"
            value="true"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ drawerItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="subDrawerItem in drawerItem.items">
              <v-list-item
                :key="subDrawerItem.title"
                v-if="showDrawer(subDrawerItem.role)"
                @click="rightDrawerActionClick(subDrawerItem.route)"
              >
                <v-list-item-icon>
                  <v-icon> {{ subDrawerItem.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subDrawerItem.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <v-divider
            v-else-if="
              drawerItem.divider && showDrawer(drawerItem.role)
            "
            :key="i"
            class="my-4 mx-2"
          />
        </template>
      </v-list>
    </v-navigation-drawer>
    <LoadProjectPopup ref="LoadProjectPopup" />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadProjectPopup from '@/components/projects/LoadProjectPopup';
import FileSaver from 'file-saver';

export default {
  name: 'Navbar',
  components: {
    LoadProjectPopup,
  },
  data() {
    return {
      title: 'enrag',
      drawerLeft: false,
      drawerRight: false,
      drawerLeftItems: [
        {
          title: 'General',
          icon: 'mdi-view-dashboard',
          route: '/general',
          role: '',
        },
        {
          title: 'Mixtures',
          icon: 'mdi-flask',
          route: '/mixtures',
          role: '',
        },
        {
          title: 'Combustion Chambers',
          icon: 'mdi-fire',
          route: '/combustionchambers',
          role: '',
        },
        { divider: true, role: '' },
        {
          title: 'Calculation',
          icon: 'mdi-bomb',
          route: '/calculation',
          role: '',
        },
        { divider: true, role: '' },
        {
          title: 'Results',
          icon: 'mdi-bullseye-arrow',
          route: '/results',
          role: '',
        },
        { divider: true, role: '' },
        {
          title: 'Material Database',
          icon: 'mdi-database',
          route: '/materialdatabase',
          role: '',
        },
        {
          title: 'Mixture Database',
          icon: 'mdi-database',
          route: '/mixturedatabase',
          role: '',
        },
        {
          title: 'User Database',
          icon: 'mdi-database',
          route: '/admin/users',
          role: 'admin',
        },
        { divider: true, role: '' },
        // {
        //   title: 'Settings',
        //   icon: 'mdi-cog',
        //   route: '/settings',
        //   role: '',
        // },
      ],
      drawerRightItems: [
        {
          title: 'Project',
          role: 'loggedIn',
          items: [
            {
              title: 'New Project',
              icon: 'mdi-sparkles',
              route: 'New Project',
            },
            {
              title: 'Load From File',
              icon: 'mdi-file-import',
              route: 'Load Project From File',
            },
            {
              title: 'Save To File',
              icon: 'mdi-file-export',
              route: 'Save Project To File',
            },
            // {
            //   title: 'Load From Database',
            //   icon: 'mdi-database-export',
            //   route: '/projects/load/database',
            // },
            // {
            //   title: 'Save To Database',
            //   icon: 'mdi-database-import',
            //   route: '/projects/save/database/',
            // },
            {
              title: 'Save Users Current Project',
              icon: 'mdi-database-import',
              route: 'Upload Current Project',
            },
            {
              title: 'Load Users Current Project',
              icon: 'mdi-database-export',
              route: 'Download Current Project',
            },
          ],
        },
        {
          divider: true,
          role: 'loggedIn',
        },
        {
          title: 'Account',
          active: true,
          items: [
            {
              title: 'Login',
              icon: 'mdi-login',
              role: 'guest',
              route: '/user/login',
            },
            {
              title: 'Register',
              icon: 'mdi-account-plus',
              role: 'guest',
              route: '/user/register',
            },
            {
              title: 'Settings',
              icon: 'mdi-cog',
              role: 'loggedIn',
              route: '/user/settings',
            },
            {
              title: 'Logout',
              icon: 'mdi-logout',
              role: 'loggedIn',
              route: '/user/logout',
            },
          ],
        },
      ],
    };
  },

  methods: {
    showDrawer(role) {
      if (role === 'admin') {
        return this.admin;
      } else if (role === 'loggedIn') {
        return this.loggedIn;
      } else if (role === 'guest') {
        return !this.loggedIn;
      } else {
        return true;
      }
    },
    rightDrawerActionClick(action) {
      var answer;
      var project;
      switch (action) {
        case 'New Project':
          answer = confirm(
            'Are you sure you want to start a new project. Unsaved changes for current local project will be lost. Continue?',
          );
          if (answer) {
            this.$store.dispatch('misc/setLoading', {
              status: true,
              text: 'Creating empty Project',
            });
            this.$store
              .dispatch('projects/initProject')
              .then(response => {
                var snack = {
                  text: response.data.msg,
                  color: 'success',
                };
                this.$store.dispatch('misc/setSnack', snack);
              })
              .catch(error => {
                console.log(error);
                var snack = {
                  text: error.response.data.msg,
                  color: 'error',
                };
                this.$store.dispatch('misc/setSnack', snack);
              })
              .finally(() =>
                this.$store.dispatch('misc/setLoading', false),
              );
          }
          break;
        case 'Load Project From File':
          this.$refs.LoadProjectPopup.open();
          break;
        case 'Save Project To File':
          project = this.$store.getters['projects/currentProject'];
          this.$store.dispatch('misc/saveObjectToFile', {
            obj: project,
            filename: this.currentProjectFileName,
          });
          break;
        case 'Upload Current Project':
          this.$store.dispatch('misc/setLoading', true);
          project = this.$store.getters['projects/currentProject'];
          this.$store
            .dispatch('uploadCurrentProject', project)
            .then(response => {
              this.$store.dispatch('misc/setLoading', false);
              var snack = {
                text: response.data.msg,
                color: 'success',
              };
              this.$store.dispatch('misc/setSnack', snack);
            })
            .catch(error => {
              this.$store.dispatch('misc/setLoading', false);
              console.log(error);
              var snack = {
                text: error.response.data.msg,
                color: 'error',
              };
              this.$store.dispatch('misc/setSnack', snack);
            });
          break;
        case 'Download Current Project':
          answer = confirm(
            'Are you sure you want to load a project from database? Unsaved changes for current local project will be lost. Continue?',
          );
          if (answer) {
            this.$store.dispatch('misc/setLoading', {
              status: true,
              text: 'Fetching current project',
            });
            this.$store
              .dispatch('downloadCurrentProject')
              .then(response => {
                this.$store.dispatch('misc/setSnack', {
                  text: response.data.msg,
                  color: 'success',
                });
              })
              .catch(error => {
                console.log(error);
                this.$store.dispatch('misc/setSnack', {
                  text: error.response.data.msg,
                  color: 'error',
                });
              })
              .finally(() =>
                this.$store.dispatch('misc/setLoading', false),
              );
          }
          break;
        default:
          this.$router.push(action);
      }
    },
  },
  computed: {
    ...mapGetters(['loggedIn', 'admin', 'currentUser']),
    ...mapGetters('misc', ['logo']),
    ...mapGetters('projects', ['currentProjectFileName']),
    currentPage() {
      return this.$route.name;
    },
  },
};
</script>

<style></style>
